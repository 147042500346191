<template>
  <div id="OrderCrossConnectSummary">
    <div class="contentSummary">
      <div :class="[isPrinting ? 'print-font' : 'padding60']">
        <div class="mdl-grid">
          <div :class="isPrinting ? 'print-font' : 'mdl-cell--8-col'">
            <MyHeaderPage
              :title="
                readOnly
                  ? $t('breadcrumbs.summaryOrderCrossConnect')
                  : $t('checkCoverageSummary.titleEdit')
              "
              :date="getDateOrder(orderItem.submission_date)"
              :calendar="false"
              :simplified="isPrinting"
            />
          </div>
          <!-- Download PDF Button -->
          <div
            :class="isPrinting ? '' : 'mdl-cell--4-col'"
            style="text-align: right"
          >
            <button class="cdlButton" @click="startPrint" v-if="!isPrinting">
              Download PDF
            </button>
          </div>
        </div>
        <SummaryBox :simplified="isPrinting">
          <template #itemDescription>
            {{
              orderItem.related_products &&
              orderItem.related_products[0].group_name
            }}
          </template>
          <template #address>
            {{
              orderItem?.category_specific_fields?.cross_connect_details
                ?.datacenter_address
            }}
          </template>
          <template #nrc>{{ orderItem?.total_price?.nrc }}</template>
          <template #mrc>{{ orderItem?.total_price?.mrc }}</template>
          <template #min_duration>{{
            orderItem.starter_subscription_months
          }}</template>
          <template #renewal>{{
            orderItem.regular_subscription_months
          }}</template>
          <template #release_time>{{ orderItem.activation_days }}</template>
        </SummaryBox>

        <!-- MAIN LINE TITLE -->
        <div v-if="!isPrinting" :class="isPrinting ? '' : 'mdl-grid section'">
          <div
            :class="
              isPrinting
                ? 'simplified-label'
                : 'mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone'
            "
          >
            <h2 :class="isPrinting ? 'simplified-label' : ''">
              {{ $t("crossConnects.step2Heading") }}
            </h2>
          </div>
        </div>
        <div
          v-else-if="
            isPrinting &&
            !orderItem?.category_specific_fields?.cross_connect_details
              .master_id
          "
          :class="isPrinting ? '' : 'mdl-grid section'"
        >
          <div
            :class="
              isPrinting
                ? 'simplified-label'
                : 'mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone'
            "
          >
            <h2 :class="isPrinting ? 'simplified-label' : ''">
              {{ $t("crossConnects.step2Heading") }}
            </h2>
          </div>
        </div>
        <!-- / MAIN LINE TITLE -->

        <!-- READONLY SECTION -> LINK TO REDUNDANT LINE -->
        <div
          v-if="
            !isPrinting &&
            orderItem?.category_specific_fields?.cross_connect_details.master_id
          "
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold"
              >{{ $t("crossConnects.isMainLineReadOnly") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CrossConnectOrderSummary' + this.$i18n.locale,
                    params: {
                      item_id:
                        orderItem?.category_specific_fields
                          ?.cross_connect_details?.master_id,
                    },
                  })
                "
                target="_blank"
                >{{ $t("crossConnects.here") }}</a
              ></span
            >
          </div>
        </div>
        <!-- / READONLY SECTION -> LINK TO REDUNDANT LINE -->

        <!-- MAIN LINE A-B DEVICE -->
        <div
          id="aend-bend-main"
          class="mdl-grid aend-bend-container html2pdf__page-break"
          v-if="
            orderItem &&
            orderItem?.category_specific_fields?.cross_connect_details &&
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          style="align-content: flex-start"
        >
          <AbDevice
            :class="[!isPrinting ? 'descriptive-card' : '']"
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'A - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .room
            "
            :rack="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .rack_name
            "
            :device-port="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .device_port
            "
            :patchPanel="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .patch_panel_to_mmr
            "
          ></AbDevice>
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'B - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .room
            "
            :rack="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .rack_name
            "
            :device-port="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .device_port
            "
            :patchPanel="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .patch_panel_to_mmr
            "
            :attachment="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa
            "
            :attachmentName="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa_name
            "
            :isle="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .islet
            "
            :building="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .building_name
            "
            :wing="
              orderItem &&
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .wing_name
            "
            :is-loa-provided="
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .rack_id &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .loa &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .building
            "
          ></AbDevice>
        </div>
        <!-- / MAIN LINE A-B DEVICE -->

        <!-- RIDONDANZA -->
        <!-- TITLE  -->
        <div :class="!isPrinting ? 'mdl-grid section' : ''">
          <div
            :class="
              !isPrinting ??
              'mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone'
            "
          >
            <h2 :class="isPrinting ? 'simplified-label' : ''">
              {{ $t("crossConnects.ridondanza") }}
            </h2>
          </div>
        </div>
        <!-- / TITLE -->

        <!-- LINK TO MAIN LINE PAGE -->
        <div
          v-if="
            orderItem?.category_specific_fields?.cross_connect_details
              ?.is_redundant &&
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          class="mdl-grid info-container"
        >
          <div class="mdl-cell mdl-cell--10-col">
            <span style="font-weight: bold" v-if="!isPrinting"
              >{{ $t("crossConnects.isRedundancyMainLineReadOnly") }}
              <a
                style="
                  font-weight: bold;
                  color: #002e5f;
                  cursor: pointer;
                  text-decoration: underline;
                "
                @click="
                  this.$router.push({
                    name: 'CrossConnectOrderSummary' + this.$i18n.locale,
                    params: {
                      item_id:
                        orderItem?.category_specific_fields
                          ?.cross_connect_details?.redundancy_ids[0],
                    },
                  })
                "
                target="_blank"
                >{{ $t("crossConnects.here") }}</a
              ></span
            >
            <span v-else>{{
              $t("crossConnects.isRedundancyMainLineReadOnlyPrint")
            }}</span>
          </div>
        </div>
        <!-- / LINK TO MAIN LINE PAGE -->

        <!-- REDUNDANCY NOT ENABLED -->
        <div
          :class="!isPrinting ?? 'mdl-grid'"
          v-if="
            !orderItem?.category_specific_fields?.cross_connect_details
              ?.is_redundant
          "
        >
          <div
            :class="
              !isPrinting
                ? 'mdl-cell mdl-cell--10-col mdl-cell--12-col-tablet mdl-cell--12-col-phone'
                : ''
            "
          >
            <span
              style="font-weight: bold"
              :class="isPrinting ? 'simplified-title' : ''"
              >{{ $t("crossConnects.redundancyNotEnabled") }}</span
            >
          </div>
        </div>
        <!-- / REDUNDANCY NOT ENABLED -->

        <div
          id="aend-bend-redundancy"
          class="mdl-grid aend-bend-container html2pdf__page-break"
          v-if="
            orderItem?.category_specific_fields?.cross_connect_details
              ?.master_id
          "
          style="align-content: flex-start"
        >
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'A - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .room
            "
            :rack="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .rack_name
            "
            :device-port="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .device_and_port
            "
            :patchPanel="
              orderItem?.category_specific_fields.cross_connect_details.a_end
                .patch_panel_to_mmr
            "
            :attachment="''"
          ></AbDevice>
          <AbDevice
            class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone descriptive-card"
            :title="'B - END'"
            :room="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .room
            "
            :rack="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .rack_name
            "
            :device-port="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .device_and_port
            "
            :patchPanel="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .patch_panel_to_mmr
            "
            :attachment="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa
            "
            :attachmentName="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .loa_name
            "
            :isle="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .islet
            "
            :building="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .building_name
            "
            :wing="
              orderItem?.category_specific_fields.cross_connect_details.b_end
                .wing_name
            "
            :is-loa-provided="
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .rack_id &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .loa &&
              !orderItem.category_specific_fields.cross_connect_details.b_end
                .building
            "
          ></AbDevice>
        </div>

        <div :class="isPrinting ? '' : 'mdl-grid'" id="additional-info-section">
          <div
            :class="
              isPrinting
                ? 'simplified-label'
                : 'mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone'
            "
          >
            <h2 :class="isPrinting ? 'simplified-label' : ''">
              {{ $t("crossConnects.additionalInfo") }}
            </h2>
          </div>
        </div>
        <div v-if="!isPrinting">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">{{
                $t("crossConnects.orderDetails")
              }}</span>
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <div class="info-item">
                <span class="title"
                  >{{ $t("crossConnects.purchaseOrder") }}
                  <span class="value">
                    {{ orderItem?.client_po }}
                  </span></span
                >
              </div>
            </div>
            <div class="mdl-cell mdl-cell--8-col">
              <div class="info-item">
                <span class="title"
                  >Note
                  <span class="value" style="font-family: OpenSansSemiBold">
                    {{ orderItem?.notes }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <br />
          <div
            class="mdl-grid"
            v-if="
              orderItem?.category_specific_fields?.cross_connect_details
                ?.contact
            "
          >
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.crossConnectReferral") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("crossConnects.fullName") }}
                <span class="value" style="margin-left: 0"
                  >{{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.first_name
                  }}
                  {{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.last_name
                  }}</span
                ></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("refTicket.phoneRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields.cross_connect_details
                    .contact?.phone
                }}</span></span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("refTicket.emailRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields.cross_connect_details
                    .contact.email
                }}</span></span
              >
            </div>
          </div>
        </div>
        <div v-if="isPrinting">
          <div>
            <div style="color: #b8c4ce">
              <span class="subtitle simplified-title" style="color: #b8c4ce">{{
                $t("crossConnects.orderDetails")
              }}</span>
            </div>
            <div class="">
              <div class="info-item">
                <span class="title simplified-title"
                  >{{ $t("crossConnects.purchaseOrder") }}
                  <span class="value simplified-label">
                    {{ orderItem?.client_po }}
                  </span></span
                >
              </div>
            </div>
            <div class="">
              <div class="info-item">
                <span class="title simplified-title"
                  >Note
                  <span
                    class="value simplified-label"
                    style="font-family: OpenSansSemiBold"
                  >
                    {{ orderItem?.notes }}
                  </span></span
                >
              </div>
            </div>
          </div>
          <br />
          <div
            class=""
            v-if="
              orderItem?.category_specific_fields?.cross_connect_details
                ?.contact
            "
          >
            <div class="" style="color: #b8c4ce">
              <span class="subtitle simplified-title" style="color: #b8c4ce">
                {{ $t("crossConnects.crossConnectReferral") }}</span
              >
            </div>
            <div class="">
              <span class="title simplified-title margin-15"
                >{{ $t("crossConnects.fullName") }}
                <span class="value simplified-label" style="margin-left: 26px"
                  >{{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.first_name
                  }}
                  {{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.last_name
                  }}</span
                ></span
              >
            </div>
            <div class="">
              <span class="title simplified-title margin-15"
                >{{ $t("refTicket.phoneRef") }}
                <span
                  class="value simplified-label"
                  style="margin-left: 16px"
                  >{{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact?.phone
                  }}</span
                ></span
              >
            </div>
            <div class="">
              <span class="title simplified-title margin-15"
                >{{ $t("refTicket.emailRef") }}
                <span
                  class="value simplified-label"
                  style="margin-left: 16px"
                  >{{
                    orderItem?.category_specific_fields.cross_connect_details
                      .contact.email
                  }}</span
                ></span
              >
            </div>
          </div>
        </div>
        <div v-if="!isPrinting" class="mdl-grid">
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
          >
            <h2>{{ $t("orders.additionalRefs") }}</h2>
          </div>
        </div>
        <div v-if="!isPrinting" class="mdl-grid">
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title"
              >{{ $t("orders.orderRef") }}
              <span class="value" style="margin-left: 0">{{
                orderItem?.order_ref
              }}</span></span
            >
          </div>
        </div>

        <div v-if="!isPrinting">
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.step2Heading") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value" style="margin-left: 0"
                  >{{
                    orderItem?.category_specific_fields?.cross_connect_details
                      ?.master_order_item_ref
                      ? orderItem?.category_specific_fields
                          ?.cross_connect_details?.master_order_item_ref
                      : orderItem?.order_item_ref
                  }}
                </span></span
              >
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--12-col" style="color: #b8c4ce">
              <span class="subtitle" style="color: #b8c4ce">
                {{ $t("crossConnects.step3Heading") }}</span
              >
            </div>
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value" style="margin-left: 0">{{
                  orderItem?.category_specific_fields?.cross_connect_details
                    ?.redundancy_order_item_ref
                    ? orderItem?.category_specific_fields?.cross_connect_details
                        ?.redundancy_order_item_ref
                    : orderItem?.category_specific_fields?.cross_connect_details
                        ?.master_order_item_ref
                    ? orderItem?.order_item_ref
                    : "N/A"
                }}</span></span
              >
            </div>
          </div>
          <div class="mdl-grid">
            <div class="mdl-cell mdl-cell--4-col">
              <span class="title"
                >{{ $t("orders.requestDate") }}
                <span class="value" style="margin-left: 0">{{
                  formatDateToDDMMYYYY(orderItem.submission_date)
                }}</span></span
              >
            </div>
          </div>
        </div>
        <div v-else-if="isPrinting">
          <div class="">
            <div class="">
              <h2 class="simplified-label">
                {{ $t("orders.additionalRefs") }}
              </h2>
            </div>
          </div>
          <div class="">
            <div class="">
              <span class="title simplified-title"
                >{{ $t("orders.orderRef") }}
                <span class="value simplified-label" style="margin-left: 0">{{
                  orderItem?.order_ref
                }}</span></span
              >
            </div>
          </div>
          <div class="">
            <div class="" style="color: #b8c4ce">
              <span class="subtitle simplified-title" style="color: #b8c4ce">
                {{ $t("crossConnects.step2Heading") }}</span
              >
            </div>
            <div class="">
              <span class="title simplified-title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value simplified-label" style="margin-left: 0"
                  >{{
                    orderItem?.category_specific_fields?.cross_connect_details
                      ?.master_order_item_ref
                      ? orderItem?.category_specific_fields
                          ?.cross_connect_details?.master_order_item_ref
                      : orderItem?.order_item_ref
                  }}
                </span></span
              >
            </div>
          </div>
          <div class="">
            <div class="" style="color: #b8c4ce">
              <span class="subtitle simplified-title" style="color: #b8c4ce">
                {{ $t("crossConnects.step3Heading") }}</span
              >
            </div>
            <div class="">
              <span class="title simplified-title"
                >{{ $t("orders.orderRowRef") }}
                <span class="value simplified-label" style="margin-left: 0">{{
                  orderItem?.category_specific_fields?.cross_connect_details
                    ?.redundancy_order_item_ref
                    ? orderItem?.category_specific_fields?.cross_connect_details
                        ?.redundancy_order_item_ref
                    : orderItem?.category_specific_fields?.cross_connect_details
                        ?.master_order_item_ref
                    ? orderItem?.order_item_ref
                    : "N/A"
                }}</span></span
              >
            </div>
          </div>
          <div class="">
            <div class="">
              <span class="title simplified-title"
                >{{ $t("orders.requestDate") }}
                <span class="value simplified-label" style="margin-left: 0">{{
                  formatDateToDDMMYYYY(orderItem.submission_date)
                }}</span></span
              >
            </div>
          </div>
        </div>
        <div v-if="!isPrinting">
          <div style="text-align: center; margin-top: 60px">
            <router-link :to="'/' + $t('nav.ordersUrl')">
              <button class="cdlButton">
                {{ $t("orders.backToOrders") }}
              </button>
            </router-link>
          </div>
          <MyLoading :viewLoader="this.viewLoader" type="full" />
          <MyError
            :viewError="viewError"
            :message="$t('checkCoverageSummary.errorGeneric')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AbDevice from "@/components/CrossConnect/ABDevice.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import MyError from "@/components/MyError.vue";
import SummaryBox from "@/components/CrossConnect/SummaryBox.vue";

import "@/assets/scss/OrderNetworkCoverageSummary.scss";
import moment from "moment/moment";

export default {
  components: {
    MyHeaderPage,
    MyLoading,
    MyError,
    AbDevice,
    SummaryBox,
  },
  data() {
    return {
      dc: {},
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      orderItem: {},
      redundantData: null,
      masterData: null,
      viewLoader: false,
      viewError: false,
      readOnly: true,
      isPrinting: false,
    };
  },
  methods: {
    getDateOrder(dateOrder) {
      let nameDay = moment(
          new Date(dateOrder),
          "YYYY-MM-DD",
          this.$i18n.locale,
          true
      ).format("dddd");
      let date = moment(
          new Date(dateOrder),
          "YYYY-MM-DD, hh:mm:ss",
          this.$i18n.locale,
          true
      ).format("LL");
      return nameDay.charAt(0).toUpperCase() + nameDay.slice(1) + " " + date;
    },
    formatDateToDDMMYYYY(dateOrder) {
      return moment(new Date(dateOrder)).format("DD/MM/YYYY");
    },
    getItem(params) {
      console.log(params);
      this.viewLoader = false;
      const self = this;

      this.axios
          .get(
              `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/order/${
                  params.order_id
              }/${params.item_id}`,
              {headers: this.he}
          )
          .then((response) => {
            this.viewLoader = true;
            this.orderItem = response.data;
          })
          .then(() => {
            if (
                this.orderItem?.category_specific_fields.cross_connect_details
                    .redundancy_ids &&
                this.orderItem?.category_specific_fields.cross_connect_details
                    .redundancy_ids.length
            ) {
              this.getRedundantData(
                  this.orderItem?.category_specific_fields.cross_connect_details
                      .redundancy_ids[0]
              );
            }
          })
          .then(() => {
            if (
                this.orderItem?.category_specific_fields.cross_connect_details
                    .master_id
            ) {
              this.getMasterData(
                  this.orderItem?.category_specific_fields.cross_connect_details
                      .master_id
              );
            }
          })
          .catch(function (err) {
            console.log(err);
            self.viewLoader = true;
            document.getElementsByTagName("body")[0].classList.remove("overflow");
            return;
          });
    },
    getRedundantData(redundantMasterId) {
      this.axios
          .get(
              this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              `sales/v1/order/${this.$route.params.order_id}/${redundantMasterId}`,
              {headers: this.he}
          )
          .then((response) => {
            this.viewLoader = true;
            this.redundantData = response.data;
            console.log(this.redundantData);
          })
          .catch(function (err) {
            console.log(err);
            self.viewLoader = true;
            return;
          });
    },
    getMasterData(masterId) {
      this.axios
          .get(
              this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
              `sales/v1/order/${this.$route.params.order_id}/${masterId}`,
              {headers: this.he}
          )
          .then((response) => {
            this.viewLoader = true;
            this.masterData = response.data;
            console.log(this.masterData);
          })
          .catch(function (err) {
            console.log(err);
            self.viewLoader = true;
            return;
          });
    },
    getRelatedService(type, what, typePrice = null) {
      if (what == "name") {
        let quantity = "";
        if (type.quantity) {
          quantity = "(x" + type.quantity + ")";
        }
        return type.products.title + " " + quantity;
      } else {
        if (typePrice == "nrc") {
          return this.$PriceClean(type[0].price.nrc ?? 0);
        }
        return this.$PriceClean(type[0].price.mrc ?? 0);
      }
    },
    goToCart() {
      return this.$router.push({ name: "CartPage" + this.$i18n.locale });
    },
    startPrint() {
      this.isPrinting = true;
      this.$nextTick(() => {
        this.downloadPDF();
        this.isPrinting = false;
      });
    },
    downloadPDF() {
      const element = document.getElementById("OrderCrossConnectSummary");
      const options = {
        margin: [0.5, 0.7],
        filename: "XConnSummary.pdf",
        image: { type: "jpeg", quality: 1.0 },  // Adjusted quality for consistency
        html2canvas: { scale: 3, useCORS: true, logging: true, letterRendering: true }, // Increase scale
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
          fontFaces: {
            Arial: {
              normal: "Arial",
              bold: "Arial-BoldMT",
              italic: "Arial-ItalicMT",
              bolditalic: "Arial-BoldItalicMT"
            },
          },
        }
      };
      if (element) {
        html2pdf()
          .from(element)
          .set(options)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            let totalPages = pdf.internal.getNumberOfPages();
            let pageHeight = pdf.internal.pageSize.getHeight();
            let pageWidth =
              pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
            let footerText =
              "CDLAN SpA · Via Caldera 21, Milano, 20153 Tel +39 02 6706800 · www.cdlan.it · Tax Code/VAT no. 13064680153 · Share capital € 2.000.000 i.v.";
            let i;
            for (i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);

              pdf.text(footerText, pageWidth / 2, pageHeight - 0.5, {
                align: "center",
              });
            }
          })
          .save();
      }
    },
  },
    created() {
    this.$watch(
      () => this.$route.params.item_id,
      () => {
        this.getItem(this.$route.params);
      }
    );
  },
  mounted() {
    this.getItem(this.$route.params);
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/OrderNetworkCoverage.scss";

.print-font {
  font-family: Arial, sans-serif !important;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
  font-size: 0.75rem !important;

}

.simplified-title {
  font-size: 0.75rem !important;
  padding: 1px !important;
  margin: 2px !important;
  font-family: Arial,sans-serif !important;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
  font-weight: 400 !important; /* or another consistent weight */


}
.simplified-label {
  font-size: 1rem !important;
  padding: 1px !important;
  margin: 2px !important;
  font-family: Arial !important;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
  font-weight: 400 !important; /* or another consistent weight */

}

.simplified-padding {
  padding: 0 !important;
}

.mdl-cell-print {
  margin: 0 !important;
}

.margin-15 {
  margin-right: 15px !important;
}

.padding-0 {
  padding: 0 !important;
}

button.cdlButton {
  width: auto;
  padding: 20px 40px;
}

.section {
  margin-top: 4rem;
}

</style>
