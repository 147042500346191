<template>
  <HelpRequestConfirmed
    :confirmedRequestId="this.incidentId"
    v-if="this.incidentId"
  >
    <template #tail>
      <div class="contentButton">
        <div
          class="cdlButtonGreen"
          style="width: auto"
          @click="
            this.$router.push({
              name: this.backToListUrl,
            })
          "
        >
          {{ $t("colocation.backToList") }}
        </div>
      </div>
    </template>
  </HelpRequestConfirmed>

  <div id="incidentForm" v-else>
    <div class="contentFormTwoCol">
      <HelpRequestSolution
        :name="
          this.solution && this.solution.name
            ? this.solution.name
            : this.solution.solution_type != 'voip'
            ? this.solution.solution_type + ' - ' + this.solution.solution
            : this.solution.solution
        "
        :address="this.solution?.address"
        :dc="this.solution?.room"
      />
      <div class="contentSecondary">
        <div class="mdl-grid padding0">
          <div
            class="mdl-cell mdl-cell--12-col alert-container"
            v-if="duplicatedTicket > 0"
          >
            <span class="text-alert-danger">
              {{ $t("help.duplicatedTicketAlert") }},
              <router-link
                :to="`/${$t('nav.incidentListUrl')}/${$t(
                  'nav.detail'
                )}/${duplicatedTicket}`"
              >
                {{ $t("help.here") }}</router-link
              >
            </span>
          </div>
        </div>
        <div class="mdl-grid padding0">
          <div class="mdl-cell mdl-cell--12-col">
            <h2 class="title">{{ $t("help.incident.formTitle") }}</h2>
          </div>

          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("help.mainInfo") }}</span>
          </div>

          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{ $t("help.title") }}*</span>
              </div>
              <input
                type="text"
                maxlength="100"
                name="title"
                v-model="fields.title"
                @input="label($event)"
              />
              <span
                class="error"
                v-if="
                  this.v$.fields.title.$invalid &&
                  this.v$.fields.title.$dirty &&
                  this.v$.fields.title.required.$invalid
                "
              >
                {{ $t("help.title") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div
            class="mdl-cell mdl-cell--6-col"
            v-show="this.assetTypeFromParams === 'colocation'"
          >
            <div class="mdl-cell mdl-cell--12-col">
              <span class="labelTwo">{{ $t("help.incident.category") }}*</span>
            </div>
            <div class="cdl-input">
              <select
                name="category"
                @input="label($event)"
                style="margin-left: 0"
                v-model="fields.category"
              >
                <option
                  :value="category.id"
                  v-for="category in this.categories"
                  :key="category.id"
                >
                  {{ category.value }}
                </option>
              </select>
              <span
                class="error"
                v-if="
                  this.v$.fields.severity_level.$invalid &&
                  this.v$.fields.severity_level.$dirty
                "
              >
                {{ $t("help.incident.category") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--6-col"></div>
          <div class="mdl-cell mdl-cell--12-col">
            <div class="cdl-textarea">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{ $t("help.description") }}*</span>
              </div>
              <textarea
                maxlength="1000"
                name="description"
                id="textareaTwo"
                v-model="fields.description"
                @input="label($event)"
              ></textarea>
              <span
                class="error"
                v-if="
                  this.v$.fields.description.$invalid &&
                  this.v$.fields.description.$dirty &&
                  this.v$.fields.description.required.$invalid
                "
              >
                {{ $t("help.description") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
        </div>
        <hr />

        <div class="mdl-grid padding0">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo"
              >{{ $t("help.incident.severityLevel") }}*</span
            >
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <select
                name="severity_level"
                @input="label($event)"
                style="margin-left: 0"
                v-model="fields.severity_level"
              >
                <option value="3">
                  {{ $t("help.incident.severityLevel1") }}
                </option>
                <option :value="2">
                  {{ $t("help.incident.severityLevel2") }}
                </option>
                <option :value="1">
                  {{ $t("help.incident.severityLevel3") }}
                </option>
              </select>
              <span
                class="error"
                v-if="
                  this.v$.fields.severity_level.$invalid &&
                  this.v$.fields.severity_level.$dirty
                "
              >
                {{ $t("help.incident.severityLevel") }}
                {{ $t("checkCoverage.isRequired") }}
              </span>
            </div>
          </div>
          <div id="incidentLevelDesc" class="mdl-cell mdl-cell--12-col">
            <p class="labelTypeOperationOne marginTextOperation">
              {{ $t("help.incident.severityLevelDescTitle") }}
            </p>
            <div>
              <span class="labelTypeOperationTwo"
                >{{ $t("help.incident.severityLevel1") }}
                <span class="text">{{
                  $t("help.incident.severityLevel1Desc")
                }}</span></span
              >
            </div>

            <div>
              <span class="labelTypeOperationTwo"
                >{{ $t("help.incident.severityLevel2") }}
                <span class="text">{{
                  $t("help.incident.severityLevel2Desc")
                }}</span></span
              >
            </div>

            <div>
              <span class="labelTypeOperationTwo"
                >{{ $t("help.incident.severityLevel3") }}
                <span class="text">{{
                  $t("help.incident.severityLevel3Desc")
                }}</span></span
              >
            </div>
          </div>
        </div>

        <hr />

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelTwo">{{ $t("help.secondContact") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--6-col">
            <div class="cdl-input">
              <div class="mdl-cell mdl-cell--12-col">
                <span class="labelTwo">{{
                  $t("help.secondContactLabel")
                }}</span>
              </div>
              <input
                type="email"
                name="other_email"
                v-model="fields.other_email"
                @input="label($event)"
              />
              <span
                class="error"
                v-if="
                  this.v$.fields.other_email.$invalid &&
                  this.v$.fields.other_email.$dirty
                "
              >
                {{ $t("help.secondContact") }}
                {{ $t("help.invalidEmail") }}
              </span>
            </div>
          </div>
        </div>

        <hr />

        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <p class="labelTwo" style="margin-bottom: 0">
              {{
                $t("shipping.uploadAttach", { format: getFormatNameAttach() })
              }}
            </p>
            <span class="subTitleAttach">{{
              $t("shipping.uploadAttachSubTitle", {
                total: this.nAttach,
                size: getLimitSizeAttach(),
              })
            }}</span>
            <br />
            <span class="error" v-if="errorAttach">
              {{
                $t("shipping.attachFormat", { format: getFormatNameAttach() })
              }}
            </span>
            <span class="error" v-if="errorAttachSize">
              {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
            </span>
            <br />
            <br />
          </div>
          <div class="mdl-cell mdl-cell--12-col listAttach">
            <div
              :class="{
                hover:
                  this.fields.attachments.length == 0 ||
                  this.fields.attachments[0],
              }"
              v-if="this.nAttach >= 1"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments.length == 0"
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[0]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[0]"
                @click="removeAttach(0)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[0]" class="fileName">
                {{ this.fields.attachments[0].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 0)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[0] || this.fields.attachments[1],
              }"
              v-if="this.nAttach >= 2"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[0] && !this.fields.attachments[1]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[0] && !this.fields.attachments[1]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[1]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[1]"
                @click="removeAttach(1)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[1]" class="fileName">
                {{ this.fields.attachments[1].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 1)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[1] || this.fields.attachments[2],
              }"
              v-if="this.nAttach >= 3"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[1] && !this.fields.attachments[2]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[1] && !this.fields.attachments[2]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[2]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[2]"
                @click="removeAttach(2)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[2]" class="fileName">
                {{ this.fields.attachments[2].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 2)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[2] || this.fields.attachments[3],
              }"
              v-if="this.nAttach >= 4"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[2] && !this.fields.attachments[3]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[2] && !this.fields.attachments[3]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[3]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[3]"
                @click="removeAttach(3)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[3]" class="fileName">
                {{ this.fields.attachments[3].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 3)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
            <div
              :class="{
                hover: this.fields.attachments[3] || this.fields.attachments[4],
              }"
              v-if="this.nAttach >= 5"
            >
              <img
                src="@/assets/img/shipping/free_attach.png"
                alt="attach"
                v-if="this.fields.attachments[3] && !this.fields.attachments[4]"
              />
              <img
                src="@/assets/img/shipping/no_attach.png"
                alt="attach"
                v-if="
                  !this.fields.attachments[3] && !this.fields.attachments[4]
                "
              />
              <img
                src="@/assets/img/shipping/complete_attach.png"
                alt="attach"
                v-if="this.fields.attachments[4]"
              />
              <span
                class="material-icons deleteAttach"
                v-if="this.fields.attachments[4]"
                @click="removeAttach(4)"
                >cancel</span
              >
              <p v-if="this.fields.attachments[4]" class="fileName">
                {{ this.fields.attachments[4].name }}
              </p>
              <input
                type="file"
                @change="onFileChanged($event, 4)"
                :accept="getFormatAttach()"
                capture
                class="fileInput"
              />
            </div>
          </div>
        </div>
        <hr />

        <div class="cdlContentButtonSingle">
          <div
            class="cdlButtonSingle green"
            :style="this.disableSubmit ? ['opacity: .5'] : ''"
            @click="submit()"
          >
            {{ $t("help.submitLabel") }}
          </div>
        </div>
        <div style="margin: 20px auto; font-size: 16px" v-show="this.error">
          <span class="error">
            {{ this.error }}
          </span>
        </div>

        <div class="deleteOrder">
          <a style="color: black" @click="this.$router.go(-1)">
            {{ $t("remoteHands.deleteTicket") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import HelpRequestConfirmed from "@/components/Help/HelpRequestConfirmed.vue";
import HelpRequestSolution from "@/components/Help/HelpRequestSolution.vue";
import {
  buildSolutionLabel,
  getBackUrlFromAssetType,
  checkDuplicateTicketForAsset,
} from "@/services/TicketService.js";
import "@/assets/scss/Global.scss";
import "@/assets/scss/Help.scss";

export default {
  name: "IncidentForm",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HelpRequestConfirmed,
    HelpRequestSolution,
  },
  validations() {
    return {
      fields: {
        severity_level: { required },
        title: { required },
        description: { required },
        asset: { required },
        other_email: { email },
      },
    };
  },
  data() {
    return {
      incidentId: null,
      assetTypeFromParams: null,
      disableSubmit: false,
      nAttach: 5,
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false,
      error: "",
      categories: [],
      solution: {},
      duplicatedTicket: 0,
      fields: {
        title: null,
        description: null,
        category: null,
        severity_severity_level: "",
        other_email: "",
        asset: this.$route.params.asset_id,
        solution: "",
        attachments: [],
      },
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
        "Content-Type": "multipart/form-data",
      },
      backToListUrl: "",
    };
  },
  methods: {
    label(ev) {
      this.$LabelInput(ev);
    },
    async submit() {
      //avoid multiple click
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return false;

      const CREATE_INCIDENT_URL = "tickets/v1/incident";
      this.disableSubmit = true;
      this.axios
        .post(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + CREATE_INCIDENT_URL,
          { ...this.fields, asset_type: this.assetTypeFromParams },
          { headers: this.he }
        )
        .then((res) => {
          if (res.status !== 200) {
            window.scrollTo(0, 0);
            this.error = res.status + ": " + res.data;
            return;
          }
          //go to ticket detail
          this.incidentId = res.data;
        })
        .then(() => {
          this.disableSubmit = false;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.response.data.message;
          this.disableSubmit = false;
        });
    },
    getSolution() {
      const assetId = this.$route.params.asset_id;
      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}assets/v1/${
            this.assetTypeFromParams
          }/${assetId}`,
          { headers: this.he }
        )
        .then((res) => {
          if (res.status !== 200) {
            window.scrollTo(0, 0);
            this.error = res.status + ": " + res.data;
            return;
          }
          //set the solution hidden field
          this.fields.solution = res.data.length
            ? buildSolutionLabel(res.data[0])
            : buildSolutionLabel(res.data);
          // : `[Incident] - [${assetId}]`;
          this.solution = res.data[0] ? res.data[0] : res.data;
        })
        .then(() => {
          this.disableSubmit = false;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.response.data.message;
        });
    },
    getCategories() {
      if (
        this.assetTypeFromParams !== "colocation" &&
        this.assetTypeFromParams !== "cloud"
      )
        return;

      let setLines = "";
      if (this.assetTypeFromParams == "colocation") {
        setLines = ",lines";
      }

      const GET_CATEGORIES_URL =
        "front/v1/incident-categories?service=" +
        this.assetTypeFromParams +
        setLines;

      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + GET_CATEGORIES_URL,

          { headers: this.he }
        )
        .then((res) => {
          if (res.status !== 200) {
            window.scrollTo(0, 0);
            this.error = res.status + ": " + res.data;
            return;
          }
          //go to ticket detail
          this.categories = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.error = err.response.data.message;
        });
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach = true;
          return;
        }

        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.fields.attachments[index] = target.files[0];
      }
    },
    checkDuplicateForAsset() {
      checkDuplicateTicketForAsset(
        this.assetTypeFromParams,
        this.$route.params.asset_id,
        this.he
      ).then((res) => {
        this.duplicatedTicket = res.data.duplicated;
      });
    },
    removeAttach(index) {
      this.fields.attachments.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    // retrieve backUrl from assetType param
    this.backToListUrl = getBackUrlFromAssetType(this.$route.params.asset_type);
    // get assetType and asset id from route
    this.assetTypeFromParams = this.$route.params.asset_type;

    // get solution
    this.getSolution();
    // get incident categories
    this.getCategories();
    // get duplicated tickets
    this.checkDuplicateForAsset();
  },
};
</script>
