<template>
  <div class="cdlSideMenu" v-if="!IsMobile">
    <div
      class="contentcdlSideMenu"
      style="
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      "
    >
      <div class="head" @mouseover="onOpenMenu(99)">
        <img :src="MyLogo" alt="CDLAN S.p.A." width="70" height="70" />
      </div>

      <div class="listMenuPrimary listMenuMaster">
        <router-link
          to="/"
          class="section-link cdlMenu cdlMenu-99 cdlMenuHome"
          id="homePageMenu"
          @mouseover="onOpenMenu(99)"
          @click="setNavigator('', 99)"
        >
          <span class="material-icons">dashboard</span>
          <span class="elmMenu">Dashboard</span>
        </router-link>

        <div v-for="(item, key) in menu" :key="key">
          <div
            class="section-link cdlMenu"
            :class="['cdlMenu-' + key]"
            v-if="getPermissionForDirectUser(
                    item,
                    'area_partner'
                  )
                "
            @mouseover="onOpenMenu(key)"
          >
            <span class="material-icons" :style="item.style ?? {}">{{
              item.icon
            }}</span>
            <span class="elmMenu">{{ $t(item.label) }} {{ item.is_partner }}</span>
          </div>
        </div>
      </div>
      <div class="listMenuPrimary" style="flex-grow: 1; position: relative">
        <div class="privacyPolicy">
          <span class="elmMenu" style="margin: 20px 0"
            ><a :href="this.linkToPrivacyPolicy" target="_blank"
              >Privacy Policy</a
            ></span
          >
        </div>
      </div>
    </div>

    <div
      class="contentcdlSideMenu andMore"
      :class="{ active: openMenu, close: closeMenu }"
    >
      <div class="listMenuSecondary">
        <div v-for="(item, key) in menu" :key="key">
          <div
            class="section-link subMenu"
            :class="['andMore-' + key]"
            v-if="item.child && ctrlPerms(item.permissions)"
          >
            <div class="groupMenu">
              <div v-for="(levelOne, kOne) in item.child" :key="kOne">
                <div v-if="levelOne.child">
                  <div
                    class="titleGroupMenu"
                    :class="[
                      'subMenu-' + key + '-' + kOne,
                      { active: levelOne.open },
                    ]"
                    @click="ctlMenuOne(key, kOne)"
                    v-if="ctrlPerms(levelOne.permissions)"
                  >
                    <span>{{ $t(levelOne.label) }}</span>
                    <span
                      class="material-icons"
                      :class="[
                        'icon-' + key + '-' + kOne,
                        { active: levelOne.open },
                      ]"
                      >keyboard_arrow_down</span
                    >
                  </div>
                  <div
                    class="listMenu"
                    :class="[
                      'listMenu-' + key + '-' + kOne,
                      { active: levelOne.open },
                    ]"
                    style="margin-top: 20px"
                  >
                    <div v-for="(levelTwo, kTwo) in levelOne.child" :key="kTwo">
                      <div v-if="levelTwo.child">
                        <div
                          class="titleGroupMenu"
                          :class="['subMenu-' + key + '-' + kOne + '-' + kTwo]"
                          @click="ctlMenuTwo(key, kOne, kTwo)"
                          v-if="ctrlPerms(levelTwo.permissions)"
                        >
                          <span>{{ $t(levelTwo.label) }}</span>
                          <span
                            class="material-icons"
                            :class="[
                              'icon-' + key + '-' + kOne + '-' + kTwo,
                              { active: !levelTwo.open },
                            ]"
                            >keyboard_arrow_down</span
                          >
                        </div>
                        <div
                          v-for="(levelThree, kThree) in levelTwo.child"
                          :key="kThree"
                        >
                          <div
                            v-if="levelThree.child"
                            class="listMenu"
                            :class="[{ active: levelThree.open }]"
                            style="margin-top: 20px"
                          >
                            <div
                              class="titleGroupMenu"
                              :class="[
                                'subMenu-' +
                                  key +
                                  '-' +
                                  kOne +
                                  '-' +
                                  kTwo +
                                  '-' +
                                  kThree,
                              ]"
                              @click="ctlMenuThree(key, kOne, kTwo, kThree)"
                              v-if="ctrlPerms(levelThree.permissions)"
                            >
                              <span>{{ $t(levelThree.label) }}</span>
                              <span
                                class="material-icons"
                                :class="[
                                  'icon-' +
                                    key +
                                    '-' +
                                    kOne +
                                    '-' +
                                    kTwo +
                                    '-' +
                                    kThree,
                                  { active: !levelThree.open },
                                ]"
                                >keyboard_arrow_down</span
                              >
                            </div>
                            <div
                              v-for="(levelFour, kFour) in levelThree.child"
                              :key="kFour"
                            >
                              <div
                                class="listMenu"
                                :class="[
                                  'listMenu-' +
                                    key +
                                    '-' +
                                    kOne +
                                    '-' +
                                    kTwo +
                                    '-' +
                                    kThree +
                                    '-' +
                                    kFour,
                                  { active: levelFour.open },
                                ]"
                                style="margin-top: 20px"
                              >
                                <div
                                  class="listMenu active marginLeft0"
                                  v-if="ctrlPerms(levelFour.permissions)"
                                >
                                  <router-link
                                    :to="'/' + $t(levelFour.url)"
                                    @click="
                                      setNavigator(
                                        $t(levelFour.label),
                                        key,
                                        kOne,
                                        kTwo,
                                        kThree,
                                        kFour
                                      )
                                    "
                                    :id="'menu-' + $t(levelFour.url)"
                                    >{{ $t(levelFour.label) }}
                                  </router-link>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-else
                            class="listMenu"
                            :class="[
                              'listMenu-' +
                                key +
                                '-' +
                                kOne +
                                '-' +
                                kTwo +
                                '-' +
                                kThree,
                              { active: levelThree.open },
                            ]"
                            style="margin-top: 20px"
                          >
                            <div
                              class="listMenu active marginLeft0"
                              v-if="
                                getPermissionForDirectUser(
                                  levelThree,
                                  'verifica_copertura',
                                  'nav.checkCoverageUrl'
                                )
                              "
                            >
                              <router-link
                                :to="'/' + $t(levelThree.url)"
                                @click="
                                  setNavigator(
                                    $t(levelThree.label),
                                    key,
                                    kOne,
                                    kTwo,
                                    kThree
                                  )
                                "
                                :id="'menu-' + $t(levelThree.url)"
                                >{{ $t(levelThree.label) }}
                              </router-link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="listMenu active marginLeft0"
                        v-else-if="ctrlPerms(levelTwo.permissions)"
                      >
                        <router-link
                          :to="'/' + $t(levelTwo.url)"
                          @click="
                            setNavigator($t(levelTwo.label), key, kOne, kTwo)
                          "
                          :id="'menu-' + $t(levelTwo.url)"
                          >{{ $t(levelTwo.label) }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="listMenu active marginLeft0"
                  v-else-if="ctrlPerms(levelOne.permissions)">
                  <router-link
                    :to="'/' + $t(levelOne.url)"
                    :id="'menu-' + $t(levelOne.url)"
                    @click="setNavigator($t(levelOne.label), key, kOne)"
                    >{{ $t(levelOne.label) }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.privacyPolicy {
  position: absolute;
  bottom: 20px;
  font-size: 16px;
  a {
    color: #8895a7;
    &:hover {
      background: none;
      text-decoration: underline !important;
      color: #f8f9fa;
    }
  }
}
</style>

<script>
import "@/assets/scss/Menu.scss";
import MyLogo from "@/assets/img/Pittogramma.svg";
import menuJson from "@/configs/menu.json";

export default {
  name: "MenuDesktop",
  props: {
    ctrlMenuDesktop: {
      type: Boolean,
      required: true,
    },
    lang: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MyLogo: MyLogo,
      openMenu: false,
      closeMenu: true,
      menu: menuJson,
      userLanguage: "",
    };
  },
  methods: {
    setNavigator(path, index, indexSub) {
      this.mylastUrl = path;
      if (this.mylastUrl == "") {
        let list = { mylastUrl: path };
        localStorage.setItem("cdlSetups", JSON.stringify(list));
      }
      let elms = document.getElementsByClassName("cdlMenu");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });
      if (index != 99) {
        document
          .getElementsByClassName("cdlMenuHome")[0]
          .classList.remove("router-link-active");
        document
          .getElementsByClassName("cdlMenu-" + index)[0]
          .classList.add("active");
        if (indexSub != 0) {
          if (document.getElementsByClassName("subMenu-" + indexSub)[0]) {
            document
              .getElementsByClassName("subMenu-" + indexSub)[0]
              .classList.add("active");
          }
        }
      }
      this.onOpenMenu(99);
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    onOpenMenu(ctrl) {
      if (ctrl == 99) {
        this.openMenu = false;
        this.closeMenu = true;
        return;
      } else {
        this.openMenu = true;
        this.closeMenu = false;
      }
      let elms = document.getElementsByClassName("subMenu");
      Object.entries(elms).forEach((elm) => {
        elm[1].classList.remove("active");
      });
      if (document.getElementsByClassName("andMore-" + ctrl)[0]) {
        document
          .getElementsByClassName("andMore-" + ctrl)[0]
          .classList.add("active");
      }
    },
    ctlMenuOne(key, kOne) {
      let obj = this.menu[key].child[kOne];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[i].open =
          !this.menu[key].child[kOne].child[i].open;
      });
      this.menu[key].child[kOne].open = !this.menu[key].child[kOne].open;
    },
    ctlMenuTwo(key, kOne, kTwo) {
      let obj = this.menu[key].child[kOne].child[kTwo];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[kTwo].child[i].open =
          !this.menu[key].child[kOne].child[kTwo].child[i].open;
      });
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    ctlMenuThree(key, kOne, kTwo, kThree) {
      let obj = this.menu[key].child[kOne].child[kTwo].child[kThree];
      obj.child.forEach((elm, i) => {
        this.menu[key].child[kOne].child[kTwo].child[kThree].child[i].open =
          !this.menu[key].child[kOne].child[kTwo].child[kThree].child[i].open;
      });
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    getPermissionForDirectUser(level, permission, url = null) {
      let isPermissionCoverage = level.permissions.find(
        (element) => element == permission
      )
        ? true
        : false;
      let isPageCoverage = url ? level.url == url : true;
      if (!this.user.is_partner) {
        if (isPermissionCoverage && isPageCoverage) {
          return false;
        }
      }
      return this.ctrlPerms(level.permissions);
    },
  },
  watch: {
    ctrlMenuDesktop: {
      handler() {
        this.openMenu = false;
        this.closeMenu = true;
      },
      deep: true,
      immediate: true,
    },
    lang: {
      handler(newValue) {
        this.userLanguage = newValue;
      },
    },
  },

  computed: {
    linkToPrivacyPolicy() {
      const IT_LANG = "it";
      return this.$props.lang === IT_LANG
        ? "https://www.cdlan.it/privacy-cookies"
        : "https://www.cdlan.it/en/privacy-cookies";
    },
  },
};
</script>
