
import { useUserStore } from '@/stores/UserStore'

export function ctrlPermissions( permission ) {

  let permissions = useUserStore().permissions;

  if( Object.keys(permissions).length > 0 && (permission && permission.length > 0) ) {
      let youCan = false;
      permission.forEach(section => {
        let found = permissions.find((element) => element == section);
        if( found ) {
          youCan = true;
        }
      });
      return youCan;
  }

  return false;

}

export async function ctrlPermissionsRoot( permission ) {

  let permissions = Promise.resolve(useUserStore().permissions);

  let rtn = new Promise((resolve) => {
    permissions.then((values) => {
      setTimeout(() => {
        if( Object.keys(values).length > 0 && (permission && permission.length > 0) ) {
              permission.forEach(section => {
                let found = values.find((element) => element == section);
                if( found ) {
                  resolve(true);
                }
              });
            }
      }, 1500);
    });
  });

  return Promise.all([rtn]).then((value) => {
    if(value[0]) {
      return value[0];
    }
  });

}