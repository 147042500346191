<template>
  <div class="">
    <div class="contentForm">
      <h2 class="title">{{ $t("remoteHands.subTitleStep1") }}</h2>
      <span class="required" v-if="false"
        >* {{ $t("checkCoverage.required") }}</span
      >

      <div class="space"></div>

      <div class="mdl-grid padding0 noMargin">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.infoRh") }}*</span>
        </div>

        <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
          <div class="contentSelect">
            <select
              name="datacenter"
              @input="label($event)"
              @change="setDc()"
              v-model="fields.datacenter"
            >
              <option value="">
                {{ $t("remoteHands.valueDefault") }}
                {{ $t("remoteHands.labelDatacenter") }}
              </option>
              <option :value="dc" v-for="(dc, dcIndex) in dc" :key="dcIndex">
                {{ dc.name }}
              </option>
            </select>
            <span
              class="error marginLeft8"
              v-if="
                this.v$.fields.datacenter.$invalid &&
                this.v$.fields.datacenter.$dirty &&
                this.v$.fields.datacenter.required.$invalid
              "
            >
              {{ $t("remoteHands.labelDatacenter") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
          <div class="contentSelect">
            <select
              name="cage"
              @change="setRoom()"
              @input="label($event)"
              v-model="fields.cage"
            >
              <option value="">
                {{ $t("remoteHands.valueDefault") }}
                {{ $t("remoteHands.labelRoom") }}
              </option>
              <option
                :value="room"
                v-for="(room, roomIndex) in rooms"
                :key="roomIndex"
              >
                {{ room.name }}
              </option>
            </select>
            <p
              class="error marginLeft8"
              v-if="this.v$.fields.cage.$invalid && this.v$.fields.cage.$dirty"
            >
              {{ $t("remoteHands.labelRoom") }}
              {{ $t("checkCoverage.isRequired") }}
            </p>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--4-col marginPadding0 marginRight16">
          <div class="contentSelect">
            <select
              name="rack"
              @change="submit($event)"
              @input="label($event)"
              v-model="fields.rack"
            >
              <option value="">
                {{ $t("remoteHands.valueDefault") }}
                {{ $t("remoteHands.labelRack") }}
              </option>
              <option
                :value="rack"
                v-for="(rack, rackIndex) in racks"
                :key="rackIndex"
              >
                {{ rack.name }}
              </option>
            </select>
            <p
              class="error marginLeft8"
              v-if="this.v$.fields.rack.$invalid && this.v$.fields.rack.$dirty"
            >
              {{ $t("remoteHands.labelRack") }}
              {{ $t("checkCoverage.isRequired") }}
            </p>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.infoOperation") }}*</span>
        </div>
        <div
          class="mdl-cell mdl-cell--6-col mdl-cell--12-col-tablet mdl-cell--12-col-phone marginPadding0 marginRight16"
        >
          <div class="contentSelect">
            <select
              name="intervention_type"
              @change="submit($event)"
              @input="label($event)"
              v-model="fields.intervention_type"
            >
              <option value="">
                {{ $t("remoteHands.valueDefault") }}
                {{ $t("remoteHands.typeOperation") }}
              </option>
              <option :value="$t('remoteHands.Rapid')">
                {{ $t("remoteHands.Rapid") }}
              </option>
              <option :value="$t('remoteHands.Standard')">
                {{ $t("remoteHands.Standard") }}
              </option>
            </select>
            <span
              class="error marginLeft8"
              v-if="
                this.v$.fields.intervention_type.$invalid &&
                this.v$.fields.intervention_type.$dirty &&
                this.v$.fields.intervention_type.required.$invalid
              "
            >
              {{ $t("remoteHands.typeOperation") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTypeOperationOne marginTextOperation">
            {{ $t("remoteHands.typeOperationOne") }}
          </p>
          <p class="marginTextOperation">
            <span class="labelTypeOperationTwo">{{
              $t("remoteHands.typeOperationTwo")
            }}</span
            >&nbsp;
            <span class="text">{{ $t("remoteHands.typeOperationThree") }}</span>
          </p>
          <p class="marginTextOperation">
            <span class="labelTypeOperationTwo">{{
              $t("remoteHands.typeOperationFour")
            }}</span
            >&nbsp;
            <span class="text">{{ $t("remoteHands.typeOperationFive") }}</span>
          </p>
        </div>
        <div class="mdl-cell mdl-cell--12-col marginPadding0">
          <br />
          <div class="cdl-textarea" style="margin-left: 8px">
            <textarea
              maxlength="255"
              name="description"
              id="textareaTwo"
              v-model="fields.description"
              @input="label($event)"
              @change="submit"
            ></textarea>
            <label>{{ $t("remoteHands.description") }}*</label>
            <span
              class="error"
              v-if="
                this.v$.fields.description.$invalid &&
                this.v$.fields.description.$dirty &&
                this.v$.fields.description.required.$invalid
              "
            >
              {{ $t("remoteHands.description") }}
              {{ $t("checkCoverage.isRequired") }}
            </span>
          </div>
        </div>
      </div>

      <hr />

      <div class="mdl-grid padding0">
        <div class="mdl-cell mdl-cell--12-col">
          <p class="labelTwo" style="margin-bottom: 0">
            {{ $t("shipping.uploadAttach") }}
          </p>
          <span class="subTitleAttach"
            ><strong>{{
              $t("shipping.uploadAttachSubTitle", {
                total: this.nAttach,
                size: getLimitSizeAttach(),
              })
            }}</strong></span
          >
          <br />
          <span class="error" v-if="errorAttach">
            {{ $t("shipping.attachFormat", { format: getFormatNameAttach() }) }}
          </span>
          <span class="error" v-if="errorAttachSize">
            {{ $t("shipping.attachSize", { size: getLimitSizeAttach() }) }}
          </span>
          <br />
          <br />
        </div>
        <div class="mdl-cell mdl-cell--12-col listAttach">
          <div
            :class="{
              hover:
                this.fields.attachments.length == 0 ||
                this.fields.attachments[0],
            }"
            v-if="this.nAttach >= 1"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.fields.attachments.length == 0"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.fields.attachments[0]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.fields.attachments[0]"
              @click="removeAttach(0)"
              >cancel</span
            >
            <p v-if="this.fields.attachments[0]" class="fileName">
              {{ this.fields.attachments[0].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 0)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{
              hover: this.fields.attachments[0] || this.fields.attachments[1],
            }"
            v-if="this.nAttach >= 2"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.fields.attachments[0] && !this.fields.attachments[1]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.fields.attachments[0] && !this.fields.attachments[1]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.fields.attachments[1]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.fields.attachments[1]"
              @click="removeAttach(1)"
              >cancel</span
            >
            <p v-if="this.fields.attachments[1]" class="fileName">
              {{ this.fields.attachments[1].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 1)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{
              hover: this.fields.attachments[1] || this.fields.attachments[2],
            }"
            v-if="this.nAttach >= 3"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.fields.attachments[1] && !this.fields.attachments[2]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.fields.attachments[1] && !this.fields.attachments[2]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.fields.attachments[2]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.fields.attachments[2]"
              @click="removeAttach(2)"
              >cancel</span
            >
            <p v-if="this.fields.attachments[2]" class="fileName">
              {{ this.fields.attachments[2].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 2)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{
              hover: this.fields.attachments[2] || this.fields.attachments[3],
            }"
            v-if="this.nAttach >= 4"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.fields.attachments[2] && !this.fields.attachments[3]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.fields.attachments[2] && !this.fields.attachments[3]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.fields.attachments[3]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.fields.attachments[3]"
              @click="removeAttach(3)"
              >cancel</span
            >
            <p v-if="this.fields.attachments[3]" class="fileName">
              {{ this.fields.attachments[3].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 3)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
          <div
            :class="{
              hover: this.fields.attachments[3] || this.fields.attachments[4],
            }"
            v-if="this.nAttach >= 5"
          >
            <img
              src="@/assets/img/shipping/free_attach.png"
              alt="attach"
              v-if="this.fields.attachments[3] && !this.fields.attachments[4]"
            />
            <img
              src="@/assets/img/shipping/no_attach.png"
              alt="attach"
              v-if="!this.fields.attachments[3] && !this.fields.attachments[4]"
            />
            <img
              src="@/assets/img/shipping/complete_attach.png"
              alt="attach"
              v-if="this.fields.attachments[4]"
            />
            <span
              class="material-icons deleteAttach"
              v-if="this.fields.attachments[4]"
              @click="removeAttach(4)"
              >cancel</span
            >
            <p v-if="this.fields.attachments[4]" class="fileName">
              {{ this.fields.attachments[4].name }}
            </p>
            <input
              type="file"
              @change="onFileChanged($event, 4)"
              :accept="getFormatAttach()"
              capture
              class="fileInput"
            />
          </div>
        </div>
      </div>

      <br /><br /><br />
      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col marginPadding0">
          <span class="labelTwo">{{ $t("remoteHands.infoOrder") }}</span>
        </div>
        <div class="mdl-cell mdl-cell--6-col marginPadding0">
          <div class="cdl-input">
            <input
              type="text"
              maxlength="100"
              name="purchase_order"
              v-model="fields.purchase_order"
              @change="submit"
              @input="label($event)"
            />
            <label>{{ $t("remoteHands.order") }}</label>
          </div>
        </div>
        <div class="mdl-cell mdl-cell--6-col"></div>
      </div>

      <hr />

      <div class="mdl-grid">
        <div class="mdl-cell mdl-cell--12-col">
          <span class="labelTwo">{{ $t("remoteHands.otherInfo") }}</span>
        </div>
      </div>

      <RefTicket @update="updateRef" />

      <div class="cdlContentButtonDouble">
        <div class="cdlButtonSingle width170" @click="goBack()" :class="{ notAllowed: fields.fromAccessDc }">
          {{ $t("checkCoverage.stepsButtonTwo") }}
        </div>
        <div
          class="cdlButtonSingle green width170"
          id="nextStep"
          @click="goNext()"
        >
          {{ $t("checkCoverage.stepsButtonOne") }}
        </div>
      </div>

      <p class="deleteOrder" @click="cancelOrder()" v-if="!fields.fromAccessDc">
        {{ $t("remoteHands.deleteTicket") }}
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import RefTicket from "@/components/RefTicket.vue";

export default {
  name: "InfoGeneral",
  components: {
    RefTicket,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      fields: {
        datacenter: { required },
        cage: { required },
        rack: { required },
        intervention_type: { required },
        description: { required },
      },
    };
  },
  props: {
    stepData: {
      type: Object,
      required: true,
    },
    wizardData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: {
        datacenter: "",
        cage: "",
        rack: "",
        intervention_type: "",
        attachments: [],
        purchase_order: null,
        description: null,
        ref_name: null,
        ref_surname: null,
        ref_phone: null,
        ref_email: null,
        fromAccessDc: false
      },
      he: {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      },
      nAttach: 5,
      full_name: "",
      dc: [],
      rooms: [],
      racks: [],
      errorAttach: false,
      sizeAttach: 0,
      errorAttachSize: false
    };
  },
  methods: {
    getStep(type) {
      let step = this.stepData;
      if (type == "before") {
        return step - 1;
      }
      return step + 1;
    },
    async goNext() {
      window.scrollTo(0, 0);
      const result = await this.v$.$validate();
      if (result) {
        this.v$.$reset();
        this.submit();
        this.$emit("goNext");
      }
      return;
    },
    goBack() {
      if( this.fields.fromAccessDc ) {
        return;
      }
      this.$emit("goBack");
    },
    cancelOrder() {
      this.$emit("close");
    },
    label(ev) {
      this.$LabelInput(ev);
    },
    submit() {
      this.$emit("update", this.fields);
    },
    onFileChanged($event, index) {
      const target = $event.target;
      if (target && target.files) {
        if (
          this.getFormatAttach().search(target.files[0].type) < 0 ||
          target.files[0].type == ""
        ) {
          this.errorAttach = true;
          return;
        }

        this.errorAttach = false;
        this.errorAttachSize = false;

        this.sizeAttach += parseFloat(this.getSize(target.files[0].size));

        if (this.sizeAttach > this.getLimitSizeAttach()) {
          this.errorAttachSize = true;
          return;
        }

        this.fields.attachments[index] = target.files[0];
        this.submit();
      }
    },
    updateRef(stepData) {
      Object.assign(this.fields, stepData);
    },
    setDc() {
      this.submit();
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/dc-rooms/" +
            this.fields.datacenter.id,
          { headers: this.he }
        )
        .then((response) => {
          this.rooms = response.data;
        });
    },
    setRoom() {
      this.axios
        .get(
          this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
            "assets/v1/racks/" +
            this.fields.cage.id,
          { headers: this.he }
        )
        .then((response) => {
          this.racks = response.data;
        });
    },
    removeAttach(index) {
      this.fields.attachments.splice(index, 1);
    },
    getSize(sizeInBytes) {
      return (sizeInBytes / (1024 * 1024)).toFixed(2);
    },
    getLimitSizeAttach() {
      return window.VUE_APP_LIMIT_SIZE_ATTACH;
    },
    getFormatAttach() {
      return window.VUE_APP_TYPE_ATTACH;
    },
    getFormatNameAttach() {
      return window.VUE_APP_TYPE_ATTACH_NAME.replaceAll("&nbsp;", " ");
    },
    getActivity() {
      return this.wizardData.activity_type.name;
    },
    getSubTitleActivity() {
      if (this.getActivity() == "imballaggio materiale in uscita") {
        return "Se stai prenotando l'attività ai fini di un ritiro e non è presente la lettera di vettura sulle merci, dovrai indicare sull'imballaggio stesso il numero di ticket di questa remote hands per permettere al personale di CDLAN il riconoscimento di essa.";
      } else if (this.getActivity() == "packaging of outgoing material") {
        return "Se stai prenotando l'attività ai fini di un ritiro e non è presente la lettera di vettura sulle merci, dovrai indicare sull'imballaggio stesso il numero di ticket di questa remote hands per permettere al personale di CDLAN il riconoscimento di essa.";
      } else {
        return "remoteHands.subTitleStep2";
      }
    },
  },
  activated() {
    window.scrollTo(0, 0);

    Object.assign(this.fields, {
      datacenter: this.wizardData.datacenter,
      cage: this.wizardData.cage,
      rack: this.wizardData.rack,
      activity_type: this.wizardData.activity_type,
      intervention_type: this.wizardData.intervention_type,
      attachments: this.wizardData.attachments,
      purchase_order: this.wizardData.purchase_order,
      description: this.wizardData.description,
      fromAccessDc: this.wizardData.fromAccessDc
    });

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) +
          "tickets/v1/max-uploads?ticket_type=RemoteHand",
        { headers: this.he }
      )
      .then((response) => {
        this.nAttach = response.data.max;
      });

    this.axios
      .get(
        this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1) + "assets/v1/datacenter",
        { headers: this.he }
      )
      .then((response) => {
        if( this.wizardData.activity_type.name == 'Accesso al deposito merce accompagnato da CDLAN' || this.wizardData.activity_type.name == 'Access to the warehouse accompanied by CDLAN' ) {
          response.data.forEach((elm) => {
            if( elm.id != 4 ) {
              this.dc.push(elm);
            }
          })
        }
        else {
          this.dc = response.data;
        }
      });
  }
};
</script>
