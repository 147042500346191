import AccessRequest from "@/pages/AccessDc/AccessRequest.vue";
import AccessRequestForm from "@/pages/AccessDc/AccessRequestForm.vue";
import AccessRequestList from "@/pages/AccessDc/AccessRequestList.vue";
import AccessRequestDetails from "@/pages/AccessDc/AccessRequestDetails.vue";
import AccessRequestSummary from "@/pages/AccessDc/AccessRequestSummary.vue";
import AccessRequestAddressBook from "@/pages/AccessDc/AccessRequestAddressBook.vue";

import { createBreadCrumbs, setNavigator } from "@/router/routerFunction";


export function routesAccessDC() {
  return [
    {
      path: "/ingresso",
      name: "AccessRequestit",
      component: AccessRequest,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
        ],
        indexRoute: "ingresso",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/entry",
      name: "AccessRequesten",
      component: AccessRequest,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
        ],
        indexRoute: "entry",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/richiesta-ingresso",
      name: "AccessRequestFormit",
      component: AccessRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "ingresso",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "richiesta-ingresso",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/entry-request",
      name: "AccessRequestFormen",
      component: AccessRequestForm,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "entrance",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "entry-request",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/richiesta-ingresso-riepilogo",
      name: "AccessRequestSummaryit",
      component: AccessRequestSummary,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "ingresso",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "ingresso",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/entry-request-summary",
      name: "AccessRequestSummaryen",
      component: AccessRequestSummary,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "entrance",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "entrance",
        permissions: ["ingressi_nuovo"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/lista-richieste-ingresso",
      name: "AccessRequestListit",
      component: AccessRequestList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "lista-richieste-ingresso",
        permissions: ["ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/entry-request-list",
      name: "AccessRequestListen",
      component: AccessRequestList,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "",
          },
        ],
        indexRoute: "entry-request-list",
        permissions: ["ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/ingresso/dettaglio/:id",
      name: "AccessRequestDetailsit",
      component: AccessRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "/lista-richieste-ingresso/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/entry/detail/:id",
      name: "AccessRequestDetailsen",
      component: AccessRequestDetails,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "/entry-request-list/",
          },
          {
            name: "detail",
            url: "",
          },
        ],
        indexRoute: "",
        permissions: ["ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/rubrica",
      name: "AccessRequestAddressBookit",
      component: AccessRequestAddressBook,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "lista-richieste-ingresso",
          },
          {
            name: "address_book",
            url: "",
          },
        ],
        indexRoute: "rubrica",
        permissions: ["ingressi_nuovo", "ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
    {
      path: "/directory",
      name: "AccessRequestAddressBooken",
      component: AccessRequestAddressBook,
      meta: {
        breadcrumbs: [
          {
            name: "services",
            url: "",
          },
          {
            name: "access",
            url: "",
          },
          {
            name: "entry_request",
            url: "entry-request-list",
          },
          {
            name: "address_book",
            url: "",
          },
        ],
        indexRoute: "directory",
        permissions: ["ingressi_nuovo", "ingressi_lista"],
      },
      beforeEnter: [createBreadCrumbs, setNavigator],
    },
  ]
}