<template>
  <div class="isLink">
    <router-link to="/" @click="setNavigator()">
      <span class="material-icons">dashboard</span>
      <span>Dashboard</span>
      <span></span>
    </router-link>
  </div>
  <div v-for="(item, key) in menu" :key="key">
    <div
      class="section-link cdlMenu"
      :class="['cdlMenu-' + key]"
      @click="ctlMobileMenuOne(key)"
      v-if="getPermissionForDirectUser(
                    item,
                    'area_partner'
                  )"
    >
      <span class="material-icons">{{ item.icon }}</span>
      <span>{{ $t(item.label) }}</span>
      <span class="material-icons" v-if="!item.open">keyboard_arrow_down</span>
      <span class="material-icons" v-if="item.open">keyboard_arrow_up</span>
    </div>
    <div v-if="item.child && ctrlPerms(item.permissions)">
      <div
        v-for="(levelOne, kOne) in item.child"
        :key="kOne"
        class="subMenu"
        :class="{ active: item.open, isLink: levelOne.url }"
      >
        <router-link
          v-if="
            levelOne.url &&
            ctrlPerms(levelOne.permissions)
          "
          :to="'/' + $t(levelOne.url)"
          class="mdl-navigation__link cdlMenu"
          @click="setNavigator($t(levelOne.url))"
          >{{ $t(levelOne.label) }}</router-link
        >
        <div v-else>
          <div
            class="cdlMenu"
            @click="ctlMobileMenuTwo(key, kOne)"
            v-if="
              ctrlPerms(levelOne.permissions)
            "
          >
            <span>{{ $t(levelOne.label) }}</span>
            <span class="material-icons" v-if="!levelOne.open && levelOne.child"
              >keyboard_arrow_down</span
            >
            <span class="material-icons" v-if="levelOne.open && levelOne.child"
              >keyboard_arrow_up</span
            >
          </div>

          <div
            v-for="(levelTwo, kTwo) in levelOne.child"
            :key="kTwo"
            class="subMenu"
            :class="{ active: levelOne.open, isLink: levelTwo.url }"
          >
            <router-link
              v-if="levelTwo.url && ctrlPerms(levelTwo.permissions)"
              :to="'/' + $t(levelTwo.url)"
              class="mdl-navigation__link"
              @click="setNavigator($t(levelTwo.url))"
              >{{ $t(levelTwo.label) }}</router-link
            >
            <div v-else>
              <div
                class="cdlMenu"
                @click="ctlMobileMenuThree(key, kOne, kTwo)"
                v-if="ctrlPerms(levelTwo.permissions)"
              >
                <span>{{ $t(levelTwo.label) }}</span>
                <span
                  class="material-icons"
                  v-if="!levelTwo.open && levelTwo.child"
                  >keyboard_arrow_down</span
                >
                <span
                  class="material-icons"
                  v-if="levelTwo.open && levelTwo.child"
                  >keyboard_arrow_up</span
                >
              </div>
              <div v-if="levelTwo.child && ctrlPerms(levelTwo.permissions)">
                <div
                  v-for="(levelThree, kThree) in levelTwo.child"
                  :key="kThree"
                  class="subMenu"
                  :class="{ active: levelTwo.open, isLink: levelThree.url }"
                >
                  <router-link
                    v-if="
                      levelThree.url &&
                      getPermissionForDirectUser(
                        levelThree,
                        'verifica_copertura',
                        'nav.checkCoverageUrl'
                      )
                    "
                    :to="'/' + $t(levelThree.url)"
                    class="mdl-navigation__link cdlMenu"
                    @click="setNavigator($t(levelThree.url))"
                    >{{ $t(levelThree.label) }}</router-link
                  >
                  <div v-else>
                    <div
                      class="cdlMenu"
                      @click="ctlMobileMenuFour(key, kOne, kTwo, kThree)"
                      v-if="
                        getPermissionForDirectUser(
                          levelThree,
                          'verifica_copertura',
                          'nav.checkCoverageUrl'
                        )
                      "
                    >
                      <span>{{ $t(levelThree.label) }} kk</span>
                      <span
                        class="material-icons"
                        v-if="!levelThree.open && levelThree.child"
                        >keyboard_arrow_down</span
                      >
                      <span
                        class="material-icons"
                        v-if="levelThree.open && levelThree.child"
                        >keyboard_arrow_up</span
                      >
                    </div>
                    <div
                      v-for="(levelFour, kFour) in levelThree.child"
                      :key="kFour"
                      class="subMenu"
                      :class="{
                        active: levelThree.open,
                        isLink: levelFour.url,
                      }"
                    >
                      <router-link
                        v-if="levelFour.url && ctrlPerms(levelFour.permissions)"
                        :to="'/' + $t(levelFour.url)"
                        class="mdl-navigation__link cdlMenu"
                        @click="setNavigator($t(levelFour.url))"
                        >{{ $t(levelFour.label) }}
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="privacyPolicy">
    <span class="elmMenu"
      ><a :href="this.linkToPrivacyPolicy" target="_blank"
        >Privacy Policy</a
      ></span
    >
  </div>
</template>

<style lang="scss">
.menuMobile {
  .privacyPolicy {
    opacity: 0.6;
    text-decoration: underline;
    padding: 15px 20px;
    margin: 10px 30px;
    bottom: 40px;
    text-align: center;
    a {
      color: #f8f9fa;
    }
  }
}
</style>

<script>
import "@/assets/scss/Menu.scss";
import menuJson from "@/configs/menu.json";
// import { useUserStore } from "@/stores/UserStore";

export default {
  name: "MenuMobile",
  props: ["text", "lang", "user"],
  data() {
    return {
      menu: menuJson,
      userLanguage: "",
    };
  },
  methods: {
    ctlMobileMenuOne(key) {
      this.menu[key].open = !this.menu[key].open;
    },
    ctlMobileMenuTwo(key, kOne) {
      this.menu[key].child[kOne].open = !this.menu[key].child[kOne].open;
    },
    ctlMobileMenuThree(key, kOne, kTwo) {
      this.menu[key].child[kOne].child[kTwo].open =
        !this.menu[key].child[kOne].child[kTwo].open;
    },
    ctlMobileMenuFour(key, kOne, kTwo, kThree) {
      this.menu[key].child[kOne].child[kTwo].child[kThree].open =
        !this.menu[key].child[kOne].child[kTwo].child[kThree].open;
    },
    setNavigator() {
      this.$emit("closeMenu");
    },
    ctrlPerms(perms) {
      return this.$ctrlPermissions(perms);
    },
    getPermissionForDirectUser(level, permission, url = null) {
      let isPermissionCoverage = level.permissions.find(
        (element) => element == permission
      )
        ? true
        : false;
      let isPageCoverage = url ? level.url == url : true;
      if (!this.user.is_partner) {
        if (isPermissionCoverage && isPageCoverage) {
          return false;
        }
      }
      return this.ctrlPerms(level.permissions);
    },
  },
  watch: {
    lang: {
      handler(newValue) {
        this.userLanguage = newValue;
      },
    },
  },
  computed: {
    linkToPrivacyPolicy() {
      const IT_LANG = "it";
      return this.userLanguage === IT_LANG
        ? "https://www.cdlan.it/informativa-privacy-ai-clienti"
        : "https://www.cdlan.it/en/privacy-policy-for-customers";
    },
  },
  // mounted() {
  //   this.userLanguage = useUserStore().user?.preferences?.language;
  // },
};
</script>
