<template>
  <div style="text-align: center; flex-grow: 1;">
    <!-- Using a PNG Image Instead of SVG -->
    <img v-if="!IsMobile" src="@/assets/img/CDLAN_Logo.png" alt="CD Lan Logo"  />
  </div>
</template>
<script>
export default {
  name: 'YourComponentName', // Replace with your component name
  props: {
    IsMobile: Boolean, // Assuming IsMobile is passed as a prop to control visibility
  },
};
</script>
