<template>
  <div id="OrderNetworkCoverageSummary">
    <div class="contentSummary">
      <div :class="isPrintingOrderSummary ? '' : 'padding60'">
        <div class="mdl-grid" style="justify-content: space-between">
          <div class="mdl-cell mdl-cell--8-col mdl-cell--6-col-phone">
            <MyHeaderPage
              :title="$t('checkCoverageSummary.titleRead')"
              :simplified="isPrintingOrderSummary"
            />
          </div>
          <!-- Download PDF Button -->
          <div
            :class="
              isPrintingOrderSummary
                ? ''
                : 'mdl-cell mdl-cell--4-col  mdl-cell--6-col-phone'
            "
            style="text-align: right"
          >
            <button
              class="cdlButton"
              @click="startPrint"
              v-if="!isPrintingOrderSummary"
            >
              Download PDF
            </button>
          </div>
        </div>
        <SummaryBox
          :print="isPrintingOrderSummary"
          :technology="
            kit?.category_specific_fields?.network_access_details.technology
          "
        >
          <template #itemDescription>
            {{ kit.kit_description }}
          </template>
          <template #itemDetails>
            <span class="labelTwo" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''"
              >{{ $t("checkCoverage.detailLabelTech") }}

              <span class="textTwo"
                >{{
                  kit?.category_specific_fields?.network_access_details
                    ?.technology
                }}
                {{
                  getDownUp(
                    kit?.category_specific_fields?.network_access_details
                      .commercial_profile?.downstream_bandwidth,
                    kit?.category_specific_fields?.network_access_details
                      .commercial_profile?.upstream_bandwidth
                  )
                }}</span
              ></span
            >
            <p>
              <span class="labelTwo" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''"
                >Carrier
                <span class="textTwo">{{
                  kit?.category_specific_fields?.network_access_details.carrier
                }}</span>
              </span>
            </p>
          </template>
          <template #address>
            {{
              getAddress(
                kit?.category_specific_fields?.network_access_details.address
              )
            }}
          </template>
          <template #nrc>{{ kit?.base_price?.nrc }}</template>
          <template #mrc>{{ kit?.base_price?.mrc }}</template>
          <template #download
            ><small
              :class="
                isPrintingOrderSummary ? 'detailTwo labelPrint' : 'detailTwo'
              "
              >Mbps</small
            ><br />
            <span
              :class="
                isPrintingOrderSummary
                  ? 'detailThree labelPrint'
                  : 'detailThree'
              "
              >{{
                kit?.category_specific_fields?.network_access_details
                  .commercial_profile?.downstream_bandwidth
              }}</span
            ></template
          >
          <template #upload>
            <small
              :class="
                isPrintingOrderSummary ? 'detailTwo labelPrint' : 'detailTwo'
              "
              >Mbps</small
            ><br />
            <span
              :class="
                isPrintingOrderSummary
                  ? 'detailThree labelPrint'
                  : 'detailThree'
              "
              >{{
                kit?.category_specific_fields?.network_access_details
                  .commercial_profile?.upstream_bandwidth
              }}</span
            ></template
          >
          <template #min_duration>{{
            kit?.starter_subscription_months
          }}</template>
          <template #renewal>{{ kit?.regular_subscription_months }}</template>
          <template #release_time>{{ kit?.activation_days }}</template>
        </SummaryBox>

        <div class="mdl-grid">
          <div
            :class="isPrintingOrderSummary ? '' : 'mdl-cell'"
            class="mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2 :class="isPrintingOrderSummary ? 'print-heading' : ''">
              {{ $t("checkCoverage.titleRelatedServices") }}
            </h2>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-related-services' : ''"
          v-for="(product, keyProduct) in kit.related_products"
          :key="keyProduct"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentRelatedServices">
              <h3>{{ product.title }}</h3>
              <hr />
              <div class="contentTableFlex">
                <div class="singleElm">
                  <div>
                    <span class="title">{{
                      $t("checkCoverage.priceTantum")
                    }}</span>
                  </div>
                  <div>
                    <p class="value">€ {{ product.price?.nrc }}</p>
                  </div>
                </div>
                <div class="singleElm">
                  <div>
                    <span class="title">{{
                      $t("checkCoverage.priceMonth")
                    }}</span>
                  </div>
                  <div>
                    <p class="value">€ {{ product.price?.mrc }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="html2pdf__page-break"></div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
        >
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2 :class="isPrintingOrderSummary ? 'print-heading' : ''">
              {{ $t("checkCoverage.titleReferences") }}
            </h2>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{
              $t("checkCoverageSummary.customerDetails")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.customer") }}</span>
            <span class="value brMobile">{{
              kit.category_specific_fields?.network_access_details?.end_customer
                ?.company_name
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.customerEndPIva") }}</span>
            <span class="value brMobile">{{
              kit.category_specific_fields?.network_access_details?.end_customer
                ?.vat_id
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{
              $t("checkCoverage.customerEndFiscalCode")
            }}</span>
            <span class="value brMobile">{{
              kit.category_specific_fields?.network_access_details?.end_customer
                ?.cf
            }}</span>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
          v-if="
            kit?.category_specific_fields?.network_access_details?.contacts
              .length && getContactIndexes('technical') !== 0
          "
        >
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{ $t("checkCoverage.techRef") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              contactIndex["technical"].name
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              contactIndex["technical"].phone
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              contactIndex["technical"].email
            }}</span>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
          v-if="
            kit?.category_specific_fields?.network_access_details?.contacts
              .length && getContactIndexes('administrative') !== 0
          "
        >
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{
              $t("checkCoverage.administratorRef")
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              contactIndex["administrative"].name
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              contactIndex["administrative"].phone
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              contactIndex["technical"].email
            }}</span>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
          v-if="
            kit?.category_specific_fields?.network_access_details?.contacts
              .length && getContactIndexes('on-site') !== 0
          "
        >
          <div class="mdl-cell mdl-cell--12-col">
            <span class="labelOne">{{ $t("checkCoverage.onSiteRef") }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.nameSurname") }}</span>
            <span class="value brMobile">{{
              contactIndex["on-site"].name
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.mobile") }}</span>
            <span class="value brMobile">{{
              contactIndex["on-site"].phone
            }}</span>
          </div>
          <div class="mdl-cell mdl-cell--4-col">
            <span class="title">{{ $t("checkCoverage.email") }}</span>
            <span class="value brMobile">{{
              contactIndex["on-site"].email
            }}</span>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <span class="title">{{ $t("checkCoverage.orderRef") }}</span>
            <span class="value brMobile">{{ kit?.client_po ?? "-" }}</span>
          </div>
        </div>

        <hr />

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
        >
          <div
            class="mdl-cell mdl-cell--4-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2>{{ $t("checkCoverage.titleAdditionalInformation") }}</h2>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-related-services' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentRelatedServices">
              <h3>{{ $t("checkCoverage.titleStep3") }}</h3>
              <hr />
              <div class="contentTableFlex">
                <div class="singleElm">
                  <div>
                    <span class="title">Username</span>
                  </div>
                  <div>
                    <p class="value">{{
                      kit?.category_specific_fields?.network_access_details
                        .radius_account?.username ?? "-"
                    }}</p>
                  </div>
                </div>
                <div class="singleElm">
                  <div>
                    <span class="title">Password</span>
                  </div>
                  <div>
                    <p class="value">{{
                      kit?.category_specific_fields?.network_access_details
                      .radius_account?.password  ?? "-"
                    }}</p>
                  </div>
                </div>
                <div class="singleElm">
                  <div>
                    <span class="title">{{
                      $t("checkCoverage.noteRadius")
                    }}</span>
                  </div>
                  <div>
                    <p class="value">{{
                  kit?.category_specific_fields?.network_access_details
                    .radius_account?.notes ?? "-"
                    }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-related-services' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentRelatedServices">
              <h3>{{ $t("checkCoverage.delivery") }}</h3>
              <hr />
              <div class="contentTableFlex">
                <div class="singleElm">
                  <div>
                    <span class="title">{{ $t("checkCoverage.buildingFloor") }}</span>
                  </div>
                  <div>
                    <p class="value">{{
                        kit?.category_specific_fields?.network_access_details
                          .delivery_details?.building_floor ?? "-"
                      }}</p>
                  </div>
                </div>
                <div class="singleElm">
                  <div>
                    <span class="title">{{ $t("checkCoverage.timeSlot") }}</span>
                  </div>
                  <div>
                    <p class="value">
                      {{
                        kit?.category_specific_fields?.network_access_details
                          .delivery_details?.time_slot ?? "-"
                      }}
                    </p>
                  </div>
                </div>
                <div class="singleElm">
                  <div>
                    <span class="title">{{ $t("checkCoverage.noteInfo") }}</span>
                  </div>
                  <div>
                    <p class="value">
                      {{
                        kit?.category_specific_fields?.network_access_details
                          .delivery_details?.notes ?? "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-related-services' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentRelatedServices">
              <h3>{{ $t("checkCoverage.titleMigration") }}</h3>
              <hr />
              <div class="contentTableFlex">
                <div class="singleElm">
                  <div>
                    <span class="title">{{ $t("checkCoverage.migrationCode") }}</span>
                  </div>
                  <div>
                    <p class="value">
                      {{
                        kit?.category_specific_fields?.network_access_details
                          ?.olo2olo_migration?.code ?? "-"
                      }}
                    </p>
                  </div>
                </div>
                <div class="singleElm" :class="isPrintingOrderSummary ? 'print-additional-refs' : ''">
                  <div>
                    <span class="title" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ; margin-left:1rem`:''">{{ $t("checkCoverage.migrationNote") }}</span>
                  </div>
                  <div>
                    <p class="value" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">
                      {{
                        kit?.category_specific_fields?.network_access_details
                          ?.olo2olo_migration?.notes ?? "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-related-services' : ''"
        >
          <div class="mdl-cell mdl-cell--12-col">
            <div class="contentRelatedServices">
              <h3>{{ $t("checkCoverage.technicalDetails") }}</h3>
              <hr />
              <div class="contentTableFlex">
                <div class="singleElm" :class="isPrintingOrderSummary ? 'print-additional-refs' : ''">
                  <div>
                    <p class="value" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''" style="margin-left: 0;">
                      {{
                        kit?.notes ?? "-"
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="html2pdf__page-break" />

        <div
          class="mdl-grid"
          :class="isPrintingOrderSummary ? 'print-additional-refs' : ''"
        >
          <div
            class="mdl-cell mdl-cell--3-col mdl-cell--6-col-tablet mdl-cell--2-col-phone"
            style="width: auto"
          >
            <h2>{{ $t("orders.additionalRefs") }}</h2>
          </div>
        </div>

        <div
          class="contentTableFlex"
          :class="
            isPrintingOrderSummary ? 'print-additional-refs orders-ref' : ''
          "
        >
          <div class="singleElm">
            <div>
              <span class="title">{{ $t("orders.orderRef") }}</span>
            </div>
            <div>
              <p class="value" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">
                {{ kit?.order_ref ?? "-" }}
              </p>
            </div>
          </div>
          <div class="singleElm" >
            <div>
              <span class="title">{{ $t("orders.orderRowRef") }}</span>
            </div>
            <div>
              <p class="value" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">
                {{ kit?.order_item_ref ?? "-" }}
              </p>
            </div>
          </div>
          <div class="singleElm">
            <div>
              <span class="title">{{ $t("orders.requestDate") }}</span>
            </div>
            <div>
              <p class="value" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">
                {{ kit?.submission_date ? getDate(kit?.submission_date) : "-" }}
              </p>
            </div>
          </div>
        </div>

        <div class="contentTotal" :style="isPrintingOrderSummary? `margin-right: 4rem `:''">
          <p class="labelTotal" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">{{ $t("checkCoverage.total") }}</p>
          <p class="textTotal" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">{{ getPriceKit(kit?.total_price?.nrc) }}€</p>
        </div>

        <div class="contentTotal" :style="isPrintingOrderSummary? `margin-right: 4rem `:''">
          <p class="labelTotal" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">{{ $t("checkCoverage.totalMonths") }}</p>
          <p class="textTotal" :style="isPrintingOrderSummary? `line-height: 1.5; letter-spacing: 2px ;`:''">{{ getPriceKit(kit?.total_price?.mrc) }}€</p>
        </div>
      </div>
      <div v-if="!isPrintingOrderSummary">
        <div style="text-align: center; margin-bottom: 40px">
          <router-link :to="'/' + $t('nav.ordersUrl')">
            <button class="cdlButton">{{ $t("orders.backToOrders") }}</button>
          </router-link>
        </div>
      </div>

      <MyLoading :viewLoader="viewSubmit" type="full" />
      <MyError
        :viewError="viewError"
        :message="$t('checkCoverageSummary.errorGeneric')"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@media (min-width: 840px) {
  .mdl-cell {
    margin: 4px;
  }
}
button.cdlButton {
  width: auto;
  padding: 20px 40px;
}

#OrderNetworkCoverageSummary .contentSummary h2.print-heading {
  font-size: 1rem;
  line-height: 1.5 !important;
  letter-spacing: 2px !important;
  margin: 0;
}

.labelPrint {
  font-size: 0.8rem;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
}

.print-additional-refs.orders-ref {
  flex-direction: column;
}
#OrderNetworkCoverageSummary .contentTableFlex.orders-ref .singleElm {
  padding-left: 15px;
}

#OrderNetworkCoverageSummary .contentSummary .print-related-services,
#OrderNetworkCoverageSummary .contentSummary .print-additional-refs {
  margin: 0px;
  padding: 0px;
  font-size: 12px;
  line-height: 1.5 !important;  /* Increase line height */
  letter-spacing: 2px !important;  /* Increase letter spacing */
  h2,
  h3,
  .title {
    font-size: 0.8rem;
    line-height: 1.5 !important;  /* Increase line height */
    letter-spacing: 2px !important;  /* Increase letter spacing */
    color: #8895a7;
  }
  .value {
    font-size: 0.8rem;
  }
  .contentRelatedServices {
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    hr {
      display: none;
    }
  }
}
</style>

<script>
import html2pdf from "html2pdf.js";
import SummaryBox from "@/components/OrderNetworkCoverage/SummaryBox.vue";
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import moment from "moment";
import MyError from "@/components/MyError.vue";

export default {
  name: "SubmittedOrderSummary",
  components: {
    MyHeaderPage,
    MyLoading,
    MyError,
    SummaryBox,
  },
  data() {
    return {
      isPrintingOrderSummary: false,
      kit: {},
      contactIndex: {},
      viewSubmit: false,
      viewError: false,
      messageError: "",
      total: 0,
      totalMonths: 0,
    };
  },

  methods: {
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
    getClassKit(technology) {
      let rls = this.$KitStyle(technology, "class");
      return rls;
    },
    getPriceKit(price) {
      return this.$PriceClean(price ?? 0);
    },
    getContactIndexes(type) {
      let match =
        this.kit?.category_specific_fields?.network_access_details?.contacts.filter(
          (contact) => {
            return contact.type === type;
          }
        );

      if (!match) {
        console.log("no match found");
        this.contactIndex[type] = [];
        return 0;
      }

      this.contactIndex[type] = match[0];

      return Object.entries(match).length;
    },
    getDate(myTime) {
      return moment(myTime).format("DD/MM/YYYY", this.$i18n.locale, true);
    },
    getAddress(address) {
      // console.log(address);
      return (
        address?.address.toUpperCase() +
        " " +
        address?.number +
        " " +
        address?.city.toUpperCase() +
        " (" +
        address?.province.toUpperCase() +
        ")"
      );
    },
    getDownUp(download, upload) {
      let down = download + "Mb";
      if (download >= 1000) {
        down = download / 1000 + "Gb";
      }

      let up = upload + "Mb";
      if (upload >= 1000) {
        up = upload / 1000 + "Gb";
      }

      return down + "/" + up;
    },
    buildPage(params) {
      this.axios
        .get(
          `${this.$MyCrypto(window.VUE_APP_MISTRA_URL, 1)}sales/v1/order/${
            params.order_id
          }/${params.item_id}`,
          {
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + this.$keycloak.idToken,
            },
          }
        )
        .then((response) => {
          this.kit = response.data;
        })
        .then(() => {
          this.viewSubmit = true;
        });
    },
    startPrint() {
      this.isPrintingOrderSummary = true;
      this.$nextTick(() => {
        this.downloadPDF();
        this.isPrintingOrderSummary = false;
      });
    },
    downloadPDF() {
      const element = document.getElementById("OrderNetworkCoverageSummary");
      const options = {
        margin: [0, 0.2],
        filename:
          "OrderNetworkCoverageSummary_" + this.kit.order_item_ref + ".pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1.5, useCORS: true },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      };
      if (element) {
        html2pdf()
          .from(element)
          .set(options)
          .toPdf()
          .get("pdf")
          .then((pdf) => {
            let totalPages = pdf.internal.getNumberOfPages();
            let pageHeight = pdf.internal.pageSize.getHeight();
            let pageWidth =
              pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
            let footerText =
              "CDLAN SpA · Via Caldera 21, Milano, 20153 Tel +39 02 6706800 · www.cdlan.it · Tax Code/VAT no. 13064680153 · Share capital € 2.000.000 i.v.";
            let i;
            for (i = 1; i <= totalPages; i++) {
              pdf.setPage(i);
              pdf.setFontSize(8);
              pdf.setTextColor(150);
              pdf.text(footerText, pageWidth / 2, pageHeight - 0.5, {
                align: "center",
              });
            }
          })
          .save();
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.buildPage(this.$route.params);
  },
  beforeUpdate() {
    this.$IsLoggedIn();
    // this.buildPage();
  },
};
</script>
