<template>
  <div v-if="print">
    <div class="mdl-grid print">
      <div class="mdl-cell mdl-cell--12-col">
        <h4 class="labelTwo" style="margin: 0; line-height: 1.5 ; letter-spacing: 2px">
          {{ $t("crossConnects.crossConnectInActivation") }}
        </h4>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <h3 class="textOne" style="margin: 0px;line-height: 1.5 ; letter-spacing: 2px ">
          <slot name="itemDescription"></slot>
        </h3 >
        <slot style="line-height: 1.5 ; letter-spacing: 2px" name="itemDetails"></slot>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <span class="labelTwo">{{ $t("orders.address") }}</span>
        <p class="textTwo">
          <slot name="address"></slot>
        </p>
      </div>
      <div class="mdl-cell mdl-cell--12-col">
        <div style="display: flex; justify-content: flex-start">
          <div>
            <span class="labelTwo">{{
              $t("crossConnects.activationPrice")
            }}</span>
          </div>
          <div>
            <span class="detailThree print">€ <slot name="nrc"></slot></span>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-start">
          <div>
            <span class="labelTwo">{{ $t("crossConnects.priceMonth") }}</span>
          </div>

          <div>
            <span class="detailThree"
              >€
              <slot name="mrc"></slot>
            </span>
          </div>
        </div>
      </div>
      <div
        class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3>{{ $t("crossConnects.contractDetails") }}</h3>
        <div
          v-if="basePrice?.mrc != -1"
          class="tablePrice"
          style="display: flex; justify-content: space-between; flex-wrap: wrap"
        >
          <div class="row">
            <div class="contentImage">
              <span class="labelTwo">
                {{ $t("checkCoverage.speedDownload") }}
              </span>
            </div>
            <div class="alignCenter textRight">
              <slot name="download"></slot>
            </div>
          </div>

          <div class="row">
            <div class="contentImage">
              <span class="labelTwo">
                {{ $t("checkCoverage.speedUpload") }}
              </span>
            </div>
            <div class="alignCenter textRight">
              <slot name="upload"></slot>
            </div>
          </div>
        </div>
        <div
          v-if="basePrice?.mrc != -1"
          class="tablePrice"
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 1.5rem;
          "
        >
          <div class="row">
            <div class="contentImage">
              <span class="labelTwo">
                {{ $t("checkCoverageSummary.minDuration") }} ({{
                  $t("checkCoverageSummary.months")
                }})
              </span>
            </div>
            <div class="alignCenter">
              <span class="detailThree">
                <slot name="min_duration"></slot>
              </span>
            </div>
          </div>
          <div class="row">
            <div class="contentImage">
              <span class="labelTwo">
                {{ $t("checkCoverageSummary.renewalDuration") }} ({{
                  $t("checkCoverageSummary.months")
                }})
              </span>
            </div>
            <div class="alignCenter">
              <span class="detailThree"> <slot name="renewal"></slot></span
              ><br />
            </div>
          </div>
          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <span class="labelTwo">
                {{ $t("checkCoverageSummary.releaseTimes") }} ({{
                  $t("checkCoverageSummary.workingDays")
                }})
              </span>
            </div>
            <div
              class="alignCenter"
              style="text-align: right; width: min-content"
            >
              <span class="detailThree"><slot name="release_time"></slot></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="infoKit pink" v-else>
    <div class="mdl-grid">
      <!-- LEFT COLUMN START -->
      <div
        class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3>
          {{ $t("crossConnects.crossConnectInActivation") }}
        </h3>
        <!-- ITEM TITLE -->

        <div class="mdl-grid" style="padding-left: 0px; align-items: center">
          <div
            class="mdl-cell mdl-cell--2-col contentImage"
            style="margin-left: 0px"
          >
            <img :src="getIcon(technology)" alt="icon" width="45" />
          </div>
          <div class="mdl-cell mdl-cell--10-col">
            <h3 class="textOne" style="margin-bottom: 0">
              <slot name="itemDescription"></slot>
            </h3>
            <slot name="itemDetails"></slot>
          </div>
        </div>
        <!-- ITEM TITLE END -->

        <hr />

        <!-- ADDRESS START -->
        <div class="mdl-grid" style="padding-left: 0px">
          <div
            class="mdl-cell mdl-cell--2-col contentImage"
            style="margin-left: 0px"
          >
            <img src="@/assets/img/icon_pin.png" alt="location" width="45" />
            <!-- <span class="material-icons locationIcon">location_on</span> -->
          </div>
          <div class="mdl-cell mdl-cell--10-col">
            <span class="labelTwo">{{ $t("orders.address") }}</span>
            <p class="textOne">
              <slot name="address"></slot>
            </p>
          </div>
        </div>
        <!-- ADDRESS END -->

        <hr />
        <!-- PRICES START -->
        <div class="mdl-grid" style="padding-left: 0px">
          <div
            class="mdl-cell mdl-cell--2-col contentImage"
            style="margin-left: 0px"
          >
            <img src="@/assets/img/icon_kit_price.png" alt="icon" width="45" />
          </div>
          <div class="mdl-cell mdl-cell--10-col">
            <div style="display: flex; justify-content: space-between">
              <div>
                <span class="labelTwo">{{
                  $t("crossConnects.activationPrice")
                }}</span>
              </div>
              <div>
                <span class="detailThree" style="text-align: right"
                  >€ <slot name="nrc"></slot
                ></span>
              </div>
            </div>

            <div style="display: flex; justify-content: space-between">
              <div>
                <span class="labelTwo">{{
                  $t("crossConnects.priceMonth")
                }}</span>
              </div>

              <div>
                <span class="detailThree" style="text-align: right"
                  >€
                  <slot name="mrc"></slot>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- PRICES END -->
      <!-- /LEFT COLUMN END-->

      <!-- SEPARATOR START -->
      <div class="mdl-cell mdl-cell--2-col"></div>
      <!-- SEPARATOR END -->

      <!-- RIGHT COLUMN START -->
      <div
        class="mdl-cell mdl-cell--5-col mdl-cell--12-col-tablet mdl-cell--12-col-phone"
      >
        <h3>{{ $t("crossConnects.contractDetails") }}</h3>
        <div
          v-if="basePrice?.mrc != -1"
          class="tablePrice"
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 1.5rem;
          "
        >
          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <img src="@/assets/img/icon_kit_five.png" alt="icon" width="43" />
              <span class="detailOne textBlu">
                {{ $t("checkCoverage.speedDownload") }}
              </span>
            </div>
            <div class="alignCenter textRight">
              <slot name="download"></slot>
            </div>
          </div>

          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <img src="@/assets/img/icon_kit_four.png" alt="icon" width="43" />
              <span class="detailOne textBlu">
                {{ $t("checkCoverage.speedUpload") }}
              </span>
            </div>
            <div class="alignCenter textRight">
              <slot name="upload"></slot>
            </div>
          </div>
        </div>
        <hr style="margin: 50px auto" />
        <div
          v-if="basePrice?.mrc != -1"
          class="tablePrice"
          style="
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            row-gap: 1.5rem;
          "
        >
          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <img src="@/assets/img/duration.png" alt="icon" width="43" />
              <span class="detailOne textBlu">
                {{ $t("checkCoverageSummary.minDuration") }} ({{
                  $t("checkCoverageSummary.months")
                }})
              </span>
            </div>
            <div class="alignCenter">
              <span class="detailThree">
                <slot name="min_duration"></slot>
              </span>
            </div>
          </div>
          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <img src="@/assets/img/renewal.png" alt="icon" width="43" />
              <span class="detailOne textBlu">
                {{ $t("checkCoverageSummary.renewalDuration") }} ({{
                  $t("checkCoverageSummary.months")
                }})
              </span>
            </div>
            <div class="alignCenter">
              <span class="detailThree"> <slot name="renewal"></slot></span
              ><br />
            </div>
          </div>
          <div :class="!isMobile ? 'row' : 'column'">
            <div class="contentImage">
              <InfoFatturaIcon
                style="margin-right: 1rem"
                width="43"
                height="43"
              />
              <span class="detailOne textBlu">
                {{ $t("checkCoverageSummary.releaseTimes") }} ({{
                  $t("checkCoverageSummary.workingDays")
                }})
              </span>
            </div>
            <div
              class="alignCenter"
              style="text-align: right; width: min-content"
            >
              <span class="detailThree"><slot name="release_time"></slot></span>
            </div>
          </div>
        </div>
      </div>
      <!-- RIGHT COLUMN END -->
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/Kit.scss";
@media (min-width: 840px) {
  .mdl-cell {
    margin: 4px;
  }
}
.alignCenter {
  align-self: center;
}
.textRight {
  text-align: right;
}
.print {
  span,
  p,
  small {
    font-size: 0.8rem;
    line-height: 1.5 !important;  /* Increase line height */
    letter-spacing: 2px !important;  /* Increase letter spacing */
  }
}
</style>

<script>
import InfoFatturaIcon from "@/components/OrderNetworkCoverage/svg/cross-svg/InfoFatturaIcon.vue";

export default {
  name: "AccessSummaryBox",
  components: {
    InfoFatturaIcon,
  },
  props: {
    basePrice: Object,
    technology: String,
    isMobile: Boolean,
    print: { type: Boolean, default: false },
  },
  methods: {
    getIcon(technology) {
      let rls = this.$KitStyle(technology, "icon");
      return rls;
    },
  },
};
</script>
