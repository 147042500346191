import { useBreadCrumbsStore } from "@/stores/BreadCrumbsStore";
import { ctrlPermissionsRoot } from "@/services/Permissions";
import { SetNavigator } from "@/services/SetNavigator";

export function createBreadCrumbs(from) {
  
    if (document.getElementsByTagName("body")[0].classList.contains("overflow")) {
      document.getElementsByTagName("body")[0].classList.remove("overflow");
    }
  
    const breadCrumbsStore = useBreadCrumbsStore();
    breadCrumbsStore.setBreadCrumbs(from);
    return true;
  }
  
  export async function setNavigator(to) {
    if (to.meta.permissions && to.meta.permissions.length > 0) {
      let permission = to.meta.permissions;
      let rls = Promise.resolve(ctrlPermissionsRoot(permission));
      rls.then((values) => {
        if (values) {
          SetNavigator(to.fullPath, to.meta.indexRoute);
        } else {
          localStorage.removeItem("cdlSetups");
          window.location.href = "/";
        }
      });
    } else {
      SetNavigator(to.fullPath, to.meta.indexRoute);
    }
  }