<template>
  <div id="ColocationDetailSummary">
    <div class="contentSummary">
      <span
        class="material-icons closeOrder"
        @click="cancelOrder()"
        v-if="false"
        >close</span
      >
      <div :class="{ padding60: !IsMobile }">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--12-col">
            <MyHeaderPage
              :title="$t('colocation.titleDetails')"
              :subtitle="$t('colocation.subTitleDetails')"
              :calendar="true"
            />
          </div>
        </div>

        <div class="mdl-grid genericInfo">
          <div class="mdl-cell mdl-cell--12-col">
            <h3>{{ $t("colocation.genericInfo") }}</h3>
          </div>
          <hr />
          <div class="mdl-cell mdl-cell--4-col mdl-cell--12-col-phone">
            <span class="title"
              >{{ $t("colocation.detail.name") }}
              <span class="value">{{ rack.name }}</span></span
            >
          </div>
          <div class="mdl-cell mdl-cell--6-col" style="display: inline-flex">
            <div>
              <span class="title">{{ $t("colocation.detail.dc") }}</span>
            </div>
            <div>
              <p class="value">{{ rack.room }} {{ rack.address }}</p>
            </div>
          </div>
          <hr />
        </div>
        <div class="moreInfo">
          <div
            :class="{
              'mdl-grid': IsMobile,
              'mdl-cell mdl-cell--3-col': !IsMobile,
            }"
          >
            <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-phone">
              <div>
                <span class="title">{{ $t("colocation.detail.isle") }}</span>
              </div>
              <div>
                <p class="value">{{ rack.isle }}</p>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-phone">
              <div>
                <span class="title">{{ $t("colocation.detail.floor") }}</span>
              </div>
              <div>
                <p class="value">{{ rack.floor }}</p>
              </div>
            </div>
            <div
              class="mdl-cell mdl-cell--4-col mdl-cell--4-col-phone"
              v-if="false"
            >
              <div>
                <span class="title">{{ $t("colocation.detail.shared") }}</span>
              </div>
              <div>
                <p class="value">{{ rack.shared }}</p>
              </div>
            </div>
            <div class="mdl-cell mdl-cell--4-col mdl-cell--4-col-phone">
              <div>
                <span class="title">{{ $t("colocation.detail.type") }}</span>
              </div>
              <div>
                <p class="value">{{ rack.type }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="contentButton">
          <router-link :to="'/colocation'">
            <button class="cdlButton">{{ $t("colocation.backToList") }}</button>
          </router-link>
        </div>
      </div>

      <MyLoading :viewLoader="viewSubmit" type="" />
      <MyError
        :viewError="viewError"
        :message="$t('checkCoverageSummary.errorGeneric')"
      />
    </div>
  </div>
</template>

<script>
import MyLoading from "@/components/MyLoading.vue";
import MyHeaderPage from "@/components/MyHeaderPage.vue";
import "@/assets/scss/Solutions.scss";

export default {
  name: "ColocationDetail",
  components: {
    MyHeaderPage,
    MyLoading,
  },
  data() {
    return {
      rack: {},
      viewModal: false,
      viewSubmit: true,
      viewError: false,
      IsMobile: this.$IsMobile(),
    };
  },
  methods: {
    getEntity(entityId) {
      let he = {
        Accept: "application/json",
        Authorization: "Bearer " + this.$keycloak.idToken,
      };
      if (entityId) {
        this.axios
          .get(
            `${this.$MyCrypto(
              window.VUE_APP_MISTRA_URL,
              1
            )}assets/v1/colocation/${entityId}`,
            { headers: he }
          )
          .then((response) => {
            if (response.data.length) {
              this.rack = response.data[0];
            }
          })
          .catch(function (err) {
            self.viewSubmit = true;
            self.viewError = true;
            self.messageError = err.response.data.message;
          });
      }
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getEntity(this.$route.params.id);
  },
  beforeUpdate() {
    this.$IsLoggedIn();
  },
};
</script>
